import React, { useEffect, useState } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
  RSelect,
} from "../../../components/Component";
import { Label, Input, Row, Col, Alert } from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { useFormContext } from "react-hook-form";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import usersApis from "../../../api/master/users";
import moment from "moment";

interface SelectOptions {
  label: string;
  value: string;
}
const OtherInformation: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const methods = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const [states, setStates] = useState<SelectOptions[]>([]);

  const fetchStatesByCountry = async () => {
    try {
      const data = await usersApis.getStatesByCountry(1);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      //   if (stateId) {
      //     const state = stateOptions.find(
      //       (state: SelectOptions) => state.value === stateId
      //     );
      //     if (!state) {
      //       return;
      //     }
      //     setValue("state", state.value);
      //   }
    } catch (error) {}
  };

  const submitForm = (formData: any) => {
    console.log("Final form data:", formData);
    let submitData: any = {
      ...formData,
      lastFYTurnover: Number(formData.lastFYTurnover),
      incorporationDate: moment(formData.incorporationDate).format(
        "YYYY-MM-DD"
      ),
    };
    delete submitData.files;
    console.log("submitData", submitData);
    createSupplier(submitData);
    // Add your API submission logic here
  };
  const createSupplier = async (data: any) => {
    const response = await supplierApis.createSupplier(data);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "Supplier has been created", "success");
      navigate(`/suppliers`);
    } else {
      console.log("error", message);
      let errorMessage = "An error occurred";
      const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };

  const handleMultiSelectChange = (selectedOptions: any, name: any) => {
    if (name == "supplyStates") {
      const value = selectedOptions.map((option: any) => ({
        stateID: option.value,
        stateName: option.label,
      }));
      setValue(name, value);
    }
  };

  useEffect(() => {
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
    fetchStatesByCountry();
  }, []);
  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="minSupply"
              label="Min.Supply"
              // required={true}
              type="number"
              // disabled={action === "view"}
              placeholder="Enter Min.Supply"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="deliveryLeadDays"
              label="Delivery Lead Days"
              required={true}
              type="number"
              // disabled={action === "view"}
              placeholder="Enter Delivery Lead Days"
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col lg="4">
            <FormInput
              name="paymentTerms"
              label="Payment Terms"
              required={true}
              type="text"
              placeholder="Enter Payment Terms"
              // disabled={action === "view"}
            />
          </Col>
          <Col lg="4">
            <label className="form-label">
              Supply State<span className="mandatory">*</span>
            </label>
            <RSelect
              isMulti
              name="supplyStates"
              label="Supply State"
              options={states}
              required={true}
              // disabled={action === "view"}
              placeholder="-Select-"
              onChange={(value: any) =>
                handleMultiSelectChange(value, "supplyStates")
              }
              closeMenuOnSelect={false}
            />
          </Col>
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Submit Form
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default OtherInformation;
