import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";

interface PurchaseRequestAsideProps {
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const BudgetAside: FC<PurchaseRequestAsideProps> = ({ updateSm, sm }) => {
    const [profileName, setProfileName] = useState<string>("Abu Bin Ishtiak");
    const location = useLocation();

    useEffect(() => {
        sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{fontSize: 14 }}>Budget Management</h6>
                </div>
            </div>
            <div className="card-inner p-0">
           
                <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                        <Link to="/add-budget" className={isActive(`${process.env.PUBLIC_URL}/add-new-purchase-request`)}>
                            <Icon name="plus-fill-c" />
                            <span>Add New</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/budget-approvals" className={isActive(`${process.env.PUBLIC_URL}/approvals-purchase-request`)}>
                            <Icon name="check-fill-c" />
                            <span>Approvals</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/view-budget" className={isActive(`${process.env.PUBLIC_URL}/view-purchase-requests`)}>
                            <Icon name="eye-fill" />
                            <span>View</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/track-budget" className={isActive(`${process.env.PUBLIC_URL}/track-purchase-requests`)}>
                            <Icon name="activity-round-fill" />
                            <span>Tracking</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/analytics-budget" className={isActive(`${process.env.PUBLIC_URL}/analytics-purchase-requests`)}>
                            <Icon name="growth-fill" />

                            <span>Analytics</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default BudgetAside;
