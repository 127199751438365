import React, { useEffect, useState } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import usersApis from "../../../api/master/users";
import Swal from "sweetalert2";
import { RSelect } from "../../../components/Component";
import FormMultiSelect from "../../form-components/MultiSelectComponent";

interface SelectOptions {
  label: string;
  value: string;
}

const GeneralInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const [selectedSiteAccessibility, setSelectedSiteAccessibility] = useState<
    SelectOptions[]
  >([]);
  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);

  const selectedCountry = watch("country");

  const submitForm = (data: any) => {
    props.next(data);
  };

  const fetchCountrys = async (id?: string) => {
    try {
      const data = await usersApis.getCountrys();
      const countryOptions = data.data.map((country: any) => ({
        label: country.countryName,
        value: country.countryId.toString(),
      }));
      setCountries(countryOptions);
      if (id) {
        const country = countryOptions.find(
          (country: SelectOptions) => country.value === id.toString()
        );
        if (country) setValue("country", id);
      }
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (state) setValue("state", state.value);
      }
    } catch (error) {
      console.error("Error fetching states", error);
    }
  };

  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      if (districts.status && districts.data.length > 0) {
        const transformedData = districts.data.map((item: any) => ({
          value: item.districtId.toString(),
          label: item.districtName,
        }));
        setDistrictOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Error fetching districts", error.message);
    }
  };

  const onStateChange = (e: any) => {
    getDistricts(e);
  };

  useEffect(() => {
    fetchCountrys();
    if (selectedCountry) {
      fetchStatesByCountry(selectedCountry);
    } else {
      fetchStatesByCountry("1");
    }
  }, [selectedCountry]);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="referenceID"
              label="User ID"
              required={true}
              type="text"
              maxLength={10}
              placeholder="Enter User ID"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="name"
              label="User Name"
              required={true}
              type="text"
              maxLength={50}
              placeholder="Enter User Name"
            />
          </Col>
          <Col lg="4">
            <FormMultiSelect
              label="Sales Territory"
              name="prefferedStates"
              options={states}
              placeholder="Select Sales Territory"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="email"
              label="Email Address"
              type="email"
              required={true}
              maxLength={50}
              placeholder="Enter Email Address"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="phoneNumber"
              label="Phone Number"
              required={true}
              type="text"
              maxLength={10}
              placeholder="Enter Phone Number"
              pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="country"
              label="Country"
              required={true}
              options={countries}
              placeholder="Enter Country"
              onChange={(e) => fetchStatesByCountry(e)}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="state"
              label="State"
              required={true}
              options={states}
              placeholder="Select State"
              onChange={(e) => onStateChange(e)}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="district"
              label="District"
              required={true}
              options={districtOptions}
              placeholder="Select District"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="city"
              label="City"
              required={true}
              placeholder="Enter City"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="area"
              label="Area"
              required={true}
              placeholder="Enter Area"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="PIN"
              label="PIN"
              type="number"
              required={true}
              maxLength={6}
              placeholder="Enter PIN"
            />
          </Col>
          <Col lg="4">
            <FormDatePicker
              name="DOB"
              label="Date of Birth"
              maxDate={new Date()}
              showYearDropdown={true}
              scrollableYearDropdown={true}
            />
          </Col>
          <Col lg="4">
            <FormDatePicker name="DOJ" label="Date of Joining" />
          </Col>
          <Col lg="4">
            <FormInput
              name="bloodGroup"
              label="Blood Group"
              type="text"
              placeholder="Enter Blood Group"
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default React.memo(GeneralInformation);
