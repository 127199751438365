import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { reSetPassword } from "../../api/auth/authService";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorVal, setError] = useState<string>("");

    const handleFormSubmit = async (data: any) => {
        console.log("handelSubmit", data)
        setLoading(true);
        try {
            const resData = await reSetPassword(data)
            if (resData.status) {
                setTimeout(() => {
                    navigate('/success',
                        {
                            state:
                            {
                                title: "Password Reset Instructions Sent.",
                                titleColor:"",
                                description: "Check your email for instructions to reset your password. If you don't receive it soon, please check your spam folder or contact support.",
                                descriptionColor:""
                            }
                        });
                }, 1000);
                setLoading(false);
            } else {
                setError(data.message);
            }
        } catch (err: any) {
            console.log('Login failed:', err.message);
            setError(err.message);
            setLoading(false);
        }
    }
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues
    } = useForm();
    return (
        <>
            <Head title="Reset-Password" />
            <Block className="nk-block-middle nk-auth-body  wide-xs">
                <div className="brand-logo pb-4 text-center">
                    <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
                        <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                        <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
                    </Link>
                </div>
                <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                    <BlockHead>
                        <BlockContent>
                            <BlockTitle tag="h5">Reset password</BlockTitle>
                            <BlockDes>
                                <p>If you forgot your password, well, then we'll email you instructions to reset your password.</p>
                            </BlockDes>
                        </BlockContent>
                    </BlockHead>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="default-01">
                                    Email
                                </label>
                            </div>
                            <input
                                type="text"
                                className="form-control form-control-lg"
                                id="default-01"
                                {...register("email", { required: "This field is required" })}
                                placeholder="Enter your email address"
                            />
                        </div>
                        <div className="form-group">
                            <Button color="primary" size="lg" className="btn-block">
                                Send Reset Link
                            </Button>
                        </div>
                    </form>
                    <div className="form-note-s2 text-center pt-4">
                        <Link to={`/`}>
                            <strong>Return to login</strong>
                        </Link>
                    </div>
                </PreviewCard>
            </Block>
            <AuthFooter />
        </>
    );
};
export default ResetPassword;
