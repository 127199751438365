import React, { useState, useEffect, FC } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Label, Input, Row, Col, Button, Alert } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormTextArea from "../../form-components/TextAreaComponent";
import axios from "../../../axios";
import Swal from "sweetalert2";
import { Icon } from "../../../components/Component";
import costCenterApis from "../../../api/master/costCenter";
import rolesApi from "../../../api/master/roles";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
interface SelectOptions {
  label: string;
  value: string;
}
const CostCenterMaster: FC<{
  id?: string | null;
  formAction?: string | null;
  setAction?: () => void;
  toggleModelPopUp: () => void;
}> = ({ id, formAction, toggleModelPopUp }) => {
  const [action, setAction] = useState(formAction);
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = methods;
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [roles, setRoles] = useState<SelectOptions[]>([]);
  const onSubmit = async (data: any) => {
    if (!action && !id) {
      const response = await costCenterApis.createCostCenter(data);
      const { status, message } = response;
      if (status) {
        Swal.fire("Created!", "Cost Center has been created", "success");
        toggleModelPopUp();
      } else {
        let errorMessage = "An error occurred";
        const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    } else if (action === "edit") {
      const response = await costCenterApis.updateCostCenterById(id, data);
      const { status, message } = response;
      if (status) {
        Swal.fire("Updated!", "Cost Center has been updated", "success");
        toggleModelPopUp();
      } else {
        let errorMessage = "An error occurred";
        const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    }
  };
  const fetchCostCenterById = async () => {
    try {
      console.log("ffff", id, formAction);
      console.log("formAction");
      const { data, status, message } =
        await costCenterApis.fetchCostCenterById(id);
      if (status) {
        reset((previousValues) => {
          const newData = Object.keys(previousValues).reduce((acc, key) => {
            return {
              ...acc,
              [key]: data[key]?.toString(),
            };
          }, {});

          return newData;
        });
        setValue("roles", data.roles.map((item: any) => item.roleID.toString()));
      } else {
        toggleModelPopUp();
        Swal.fire("Failed!", message, "error");
      }
    } catch (err) {
      throw err;
    }
  };
  const fetchRoles = async () => {
    try {
      const { data, status, message } = await rolesApi.fetchRolesData();

      if (status) {
        setRoles(
          data.map((obj: any) => ({
            label: obj.roleName,
            value: obj.roleID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  useEffect(() => {
    fetchRoles();
  }, []);
  useEffect(() => {
    if (id) {
      fetchCostCenterById();
    }
  }, [id]);

  return (
    <>
      <h5 className="title">
        {action === "edit"
          ? "Edit Cost Center"
          : action === "view"
          ? "View Cost Center"
          : "Create Cost Center"}
      </h5>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <div style={{ marginTop: "15px" }}></div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-4">
              <FormInput
                name="referenceID"
                label="Cost Center ID"
                type="text"
                disabled={action === "edit"}
                placeholder="Enter Cost Center ID"
                required={true}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                name="costCenterName"
                label="Cost Center Name"
                type="text"
                placeholder="Enter Cost Center Name"
                required={true}
              />
            </div>
            <div className="col-md-4">
              <FormMultiSelect
                disabled={action === "view"}
                name="roles"
                label="Select Role"
                required={true}
                options={roles}
              />
            </div>
          </div>
          <div style={{ marginTop: "15px" }}></div>
          <div className="col-md-12">
            <FormTextArea
              disabled={action === "view"}
              name="description"
              label="Description"
              placeholder="Enter Description"
              required={true}
              maxLength={50}
            />
          </div>

          {action !== "view" ? (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button
                      className="btn btn-primary btn-md"
                      type="submit"
                      color="primary"
                    >
                      <span>{action === "edit" ? "Update" : "Submit"}</span>
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleModelPopUp();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          ) : (
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <div
                      className="btn btn-primary btn-md"
                      color="primary"
                      onClick={() => setAction("edit")}
                    >
                      <Icon name="pen" />
                      <span>Edit</span>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default CostCenterMaster;
