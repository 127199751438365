import makeApiCall from "./axiosIndex";

const supplierApis = {
  fetchSupplier: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchSupplier" }),
  deleteSupplierById: (supplierId: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteSupplierById`,
      params: { id: supplierId },
    }),
  createSupplier: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createSupplier",
      data,
    }),
  updateSupplierById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/seller/updateSupplierById`,
      params: { id: id },
      data,
    }),
  fetchSupplierById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchSupplierById`,
      params: { id },
    }),
  createSupplierCategory: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createSupplierCategory",
      data,
    }),
  updateSupplierCategoryById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/seller/updateSupplierCategoryById`,
      params: { id },
      data,
    }),
  fetchSupplierCategoryById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchSupplierCategoryById`,
      params: { id },
    }),
  deleteSupplierCategoryById: (supplierCategoryID: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteSupplierCategoryById`,
      params: { id: supplierCategoryID },
    }),
  fetchSupplierCategory: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchSupplierCategory" }),
  fetchIndustryType: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/misc/allIndustryTypes" }),
  getContructionsMaterialTypes: (): Promise<any> => makeApiCall<any>({ 
    method: 'get', 
    url: `/misc/constructionMaterials`, 
  }), 
};

export default supplierApis;
