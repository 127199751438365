import React from "react";
import { useFormContext } from "react-hook-form";
import { FieldErrors, FieldValues } from "react-hook-form";

interface TextAreaProps {
  name: string;
  label: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  rows?: number;
  pattern?: RegExp;
  customValidation?: { validate: (value: string) => boolean | string };
  disabled?: boolean;
  defaultValue?: string;
}

const FormTextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  required = false,
  minLength,
  maxLength,
  placeholder,
  rows = 3,
  pattern,
  customValidation,
  disabled,
  defaultValue,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorMessage = getErrorMessage(errors, name);

  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        {label}
        {required ? <span className="mandatory">*</span> : ""}
      </label>
      <textarea
        id={name}
        placeholder={placeholder}
        rows={rows}
        className={`form-control ${errorMessage ? "is-invalid" : ""}`}
        disabled={disabled}
        defaultValue={defaultValue}
        {...register(name, {
          required: required ? "This field is required" : undefined,
          minLength: minLength
            ? {
                value: minLength,
                message: `Must be at least ${minLength} characters`,
              }
            : undefined,
          maxLength: maxLength
            ? {
                value: maxLength,
                message: `Cannot be more than ${maxLength} characters`,
              }
            : undefined,
          pattern: pattern
            ? { value: pattern, message: "Invalid format" }
            : undefined,
          validate: customValidation ? customValidation.validate : undefined,
        })}
      />
      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
};

function getErrorMessage<T extends FieldValues>(
  errors: FieldErrors<T>,
  fieldName: string
): string | undefined {
  // Split the fieldName into parts to handle nested fields like "blocks[0].name"
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean); // This splits on brackets and dots, and removes empty strings
  let currentError: any = errors; // Start with the whole errors object

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part]; // Navigate deeper into the errors object
    } else {
      return undefined; // No error found for this part
    }
  }

  // If a FieldError is found and it has a message, return it
  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined; // No message found
}
export default FormTextArea;
