import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Icon } from "../../components/Component";
interface SignatureModalProps {
  modal: boolean;
  toggle: () => void;
  onSave: (croppedImage: string) => void;
}
const SignatureModal: React.FC<SignatureModalProps> = ({
  modal,
  toggle,
  onSave,
}) => {
  const sigCanvas = useRef<any>(null);

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const saveSignature = () => {
    const dataUrl = sigCanvas?.current?.toDataURL();
    if (dataUrl) {
      onSave(dataUrl);
    }
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-dialog-centered"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>Signature</ModalHeader>
        <ModalBody>
          <div style={{ height: "400px" }}>
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{
                className: "sigCanvas",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <Button
                className="btn btn-md"
                type="button"
                color=""
                onClick={clearSignature}
              >
                <Icon name="redo" />
                <span>Reset</span>
              </Button>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary btn-md"
            type="submit"
            color="primary"
            onClick={saveSignature}
          >
            Save
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SignatureModal;
