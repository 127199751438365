import React, { useEffect, FC } from "react";
import { Modal, ModalBody, Form, Row, Alert, Col, Button } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import { Icon } from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";
import dayjs from "dayjs";
import { Popover } from "antd";
const TAX_SLAP_DROPDOWN = [
  {
    label: "0%",
    value: "0",
  },
  {
    label: "5%",
    value: "1",
  },
  {
    label: "12%",
    value: "2",
  },
  {
    label: "18%",
    value: "3",
  },
  {
    label: "28%",
    value: "4",
  },
];
const EditPoModal: FC<any> = ({
  status,
  modal,
  mode,
  closeModal,
  formData,
  append,
  update,
  taxConfiguration,
  needByDateType,
  deliveryAddressType,
}) => {
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
   

    if (modal && formData.item) {
      const {
        category,
        materialName,
        categoryId,
        materialId,
        hsnCode,
        quantity,
        uomId,
        uom,
        unitPrice,
        cgstPercentage,
        sgstPercentage,
        igstPercentage,
        deliveryAddressId,
        description,
        needByDate,
        file,
        deliveryAddress,
        changeRequestUnitPrice,
        changeRequestNeedByDate,
        changeRequestQuantity,
      } = formData.item;
      console.log(formData.item);
      setValue("materialCategory", category);
      setValue("materialName", materialName);
      setValue("hsnCode", hsnCode);
      setValue("uomId", uom);
      if (changeRequestQuantity&&mode=="CR") {
        setValue("quantity", Number(changeRequestQuantity));
      } else {
        setValue("quantity", Number(quantity));
      }
      if (changeRequestUnitPrice&&mode=="CR") {
        setValue("unitPrice", Number(changeRequestUnitPrice));
      } else {
        setValue("unitPrice", Number(unitPrice));
      }

      setValue("description", description);
      if (formData.index != null) {
        setValue("deliveryAddressId", deliveryAddress?.deliveryLocationName);
        if (changeRequestNeedByDate&&mode=="CR") {
          setValue("needByDate", dayjs(changeRequestNeedByDate));
        } else {
          setValue("needByDate", dayjs(needByDate));
        }
      }
      if (taxConfiguration?.gstType == "IGST") {
        setValue("igst", Number(igstPercentage) + "%");
      } else {
        setValue("cgst", Number(cgstPercentage) + Number(sgstPercentage) + "%");
      }
    }
  }, [modal]);

  const submitForm = (data: any) => {
    const { quantity, unitPrice, needByDate } = data;
    const {igstPercentage, cgstPercentage, sgstPercentage} =formData?.item;
    const updatedFormData:any= {
      quantity,
      unitPrice,
      needByDate,
      deliveryAddressId: formData.item?.deliveryAddressId,
      netAmount: quantity * unitPrice,
    }
    if (taxConfiguration?.gstType == "IGST") {
      const igst = (Number(igstPercentage) * updatedFormData?.netAmount) / 100;
      updatedFormData.igst= igst;
      updatedFormData.lineTotal =updatedFormData?.netAmount + igst
    } else {
      const cgst= (Number(cgstPercentage) * updatedFormData?.netAmount) / 100;
      updatedFormData.cgst= cgst;
      const sgst = (Number(sgstPercentage) * updatedFormData?.netAmount) / 100;
      updatedFormData.sgst= sgst;
      updatedFormData.lineTotal =updatedFormData?.netAmount + cgst + sgst
    }
    update(formData.index,updatedFormData);
    closeModal();
  };

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl">
      <ModalBody>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                closeModal();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <h5 className="title">
              {status === "edit"
                ? "Edit Purchase Order"
                : "Purchase Order Details"}
            </h5>
            <div style={{ marginTop: "15px" }}></div>

            <Row className="mt-12">
              <div className="col-md-4">
                <FormInput
                  name="materialCategory"
                  label="Material Category"
                  disabled={true}
                  placeholder="Select Material Category"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  name="materialName"
                  label="Material Name"
                  disabled={true}
                  placeholder="Select Material Name"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="number"
                  disabled={true}
                  name="hsnCode"
                  label="HSN/SAC"
                  pattern={/^[0-9]{6,8}$/}
                  placeholder="Enter HSN"
                />
              </div>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <div className="col-md-2">
                <FormInput
                  type="number"
                  required={true}
                  disabled={status === "view"}
                  name="quantity"
                  label="Quantity"
                  placeholder="Enter Quantity"
                />
              </div>
              <div className="col-md-2">
                <FormInput
                  name="uomId"
                  label="UOM"
                  disabled={true}
                  placeholder="Units"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="number"
                  required={true}
                  disabled={status === "view"}
                  name="unitPrice"
                  label="Price per Unit"
                  placeholder="Enter Price per Unit"
                />
              </div>
              {taxConfiguration?.gstType == "IGST" ? (
                <>
                  <div className="col-md-4">
                    <FormInput
                      name="igst"
                      label="IGST(%)"
                      required={true}
                      placeholder="Enter IGST"
                      disabled={true}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-4">
                    <FormInput
                      name="cgst"
                      label="CGST/SGST(%)"
                      disabled={true}
                      placeholder="Enter CGST"
                    />
                  </div>
                  <div style={{ marginTop: "15px" }}></div>
                </>
              )}
              <div style={{ marginTop: "15px" }}></div>
              {needByDateType === "Line Level" && (
                <div className="col-md-4">
                  <FormDatePicker
                    name="needByDate"
                    label="Need By Date"
                    required={true}
                    minDate={new Date()}
                  />
                </div>
              )}
              <div className="col-md-4">
                <FormInput
                  type="file"
                  name="attachment"
                  disabled={true}
                  label="Attachment (If any) "
                  placeholder="Enter Attachment"
                  tooltipText="e.g: Design specificaitons"
                />
                {formData?.item?.file && (
                  <div>{formData?.item?.file?.fileName}</div>
                )}
              </div>
              {deliveryAddressType === "Line Level" && (
                <div className="col-md-4">
                  <div className="form-group">
                    <FormInput
                      name="deliveryAddressId"
                      label="Delivery Address"
                      disabled={true}
                      placeholder="Select Delivery Address"
                    />
                    <Popover
                      content={
                        <ul>
                          <li>
                            {formData?.item?.deliveryAddress?.addressLine1}{" "}
                            {formData?.item?.deliveryAddress?.addressLine2}
                          </li>
                          <li>
                            {formData?.item?.deliveryAddress?.city},{" "}
                            {formData?.item?.deliveryAddress?.stateName},{" "}
                            {formData?.item?.deliveryAddress?.countryName} -{" "}
                            {formData?.item?.deliveryAddress?.pincode}
                          </li>
                        </ul>
                      }
                      title="Selected Address"
                    >
                      <p className="align-center">Address Info</p>
                    </Popover>
                  </div>
                </div>
              )}
            </Row>
            <Row className="mt-4">
              <div className="col-md-12">
                <FormTextArea
                  name="description"
                  label="Description (if any)"
                  placeholder="Enter Description"
                  disabled={true}
                  minLength={5}
                  maxLength={200}
                />
              </div>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {formData.action == "edit"&&status !== "view" && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        Update
                      </Button>
                    </li>
                  )}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default EditPoModal;
