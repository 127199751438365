import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import usersApis from "../../../api/master/users";
import CropperComponent from "../../components/CropperComponent";

const UserProfileAside = ({ updateSm, sm }) => {
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const toggleModal = () => setModal(!modal);
  const handleImageSave = (image) => {
    setCroppedImage(image);
    toggleModal();
  };
  useEffect(() => {
    sm
      ? document.body.classList.add("toggle-shown")
      : document.body.classList.remove("toggle-shown");
  }, [sm]);

  const fetchUserData = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="card-inner-group">
      <div className="card-inner">
        <div className="user-card">
          <UserAvatar
            image={croppedImage}
            text={findUpper(
              data?.firstName?.replace(/\b\w/g, (l) => l.toUpperCase()) +
                " " +
                data?.lastName?.replace(/\b\w/g, (l) => l.toUpperCase())
            )}
            theme="light"
          />
          <div className="user-info">
            <span className="lead-text">
              {data?.firstName + " " + data?.lastName}
            </span>
            <span className="sub-text" title={data?.email}>
              {data?.email?.length > 17
                ? data?.email?.substring(0, 17) + "..."
                : data?.email}
            </span>
          </div>
          <div className="user-action">
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-icon btn-trigger me-n2"
              >
                <Icon name="more-v"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#dropdownitem"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleModal();
                      }}
                    >
                      <Icon name="camera-fill"></Icon>
                      <span>Change Photo</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#dropdownitem"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="edit-fill"></Icon>
                      <span>Update Profile</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
      <div className="card-inner p-0">
        <ul className="link-list-menu">
          <li onClick={() => updateSm(false)}>
            <Link
              to={`${process.env.PUBLIC_URL}/user-profile-regular`}
              className={
                window.location.pathname ===
                `${process.env.PUBLIC_URL}/user-profile-regular`
                  ? "active"
                  : ""
              }
            >
              <Icon name="user-fill-c"></Icon>
              <span>Personal Information</span>
            </Link>
          </li>
          <li onClick={() => updateSm(false)}>
            <Link
              to={`${process.env.PUBLIC_URL}/user-profile-notification`}
              className={
                window.location.pathname ===
                `${process.env.PUBLIC_URL}/user-profile-notification`
                  ? "active"
                  : ""
              }
            >
              <Icon name="bell-fill"></Icon>
              <span>Notification</span>
            </Link>
          </li>
          <li onClick={() => updateSm(false)}>
            <Link
              to={`${process.env.PUBLIC_URL}/user-profile-activity`}
              className={
                window.location.pathname ===
                `${process.env.PUBLIC_URL}/user-profile-activity`
                  ? "active"
                  : ""
              }
            >
              <Icon name="activity-round-fill"></Icon>
              <span>Account Activity</span>
            </Link>
          </li>
          <li onClick={() => updateSm(false)}>
            <Link
              to={`${process.env.PUBLIC_URL}/user-profile-setting`}
              className={
                window.location.pathname ===
                `${process.env.PUBLIC_URL}/user-profile-setting`
                  ? "active"
                  : ""
              }
            >
              <Icon name="lock-alt-fill"></Icon>
              <span>Security Setting</span>
            </Link>
          </li>
        </ul>
      </div>
      <CropperComponent
        modal={modal}
        toggle={toggleModal}
        onSave={handleImageSave}
      />
    </div>
  );
};

export default UserProfileAside;
