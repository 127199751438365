import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from 'react-hook-form';
import {
    Form
} from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import {
    Icon,
    Button,
    RSelect,
} from "../../../components/Component";
import {Row,Col} from "reactstrap";
import FormCheckbox from "../../form-components/CheckboxComponent";
const EmergencyResponseandContingencyPlans: React.FC = (props: any) => {
    const { register, handleSubmit, watch, formState: { errors }, setValue, trigger, reset } = useFormContext();
    const submitForm = (data: any) => {
        props.next();
    };
    return (
        <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
            <Row className="gy-4">
                <Col md="6">
                    <FormInput
                        name="emergencyContactPerson"
                        label="Contact Person"
                        type="text"
                        placeholder="Enter Contact Person"
                    // disabled={action === "view"}
                    />
                </Col>
                <Col md="6">
                    <FormInput
                        name="emergencyContactNumber"
                        // disabled={action === "view"}
                        label="Emergency Contact Number"
                        type="text"
                        placeholder="Enter Emergency Contact Information"
                    />
                </Col>
                <Col md="6">
                    <FormInput
                        name="emergencyProcedures"
                        label="Emergency Procedures"
                        // disabled={action === "view"}
                        type="textarea"
                        placeholder="Enter Emergency Procedures"
                    />
                </Col>
                <Col md="6">
                    <FormInput
                        name="docsUpload3"
                        label="Docs Upload"
                        // disabled={action === "view"}
                        type="file"
                        placeholder="Docs Upload"
                    />
                </Col>
            </Row>
            <div className="actions clearfix">
                <ul>
                    <li>
                        <Button color="primary" type="submit">
                            Next
                        </Button>
                    </li>
                    <li>
                        <Button color="primary" onClick={props.prev}>
                            Previous
                        </Button>
                    </li>
                </ul>
            </div>
        </form>
    )
}

export default React.memo(EmergencyResponseandContingencyPlans);
