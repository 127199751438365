import React, { FC } from 'react'
import { Modal, ModalBody, Table } from 'reactstrap'

const ChangeFromBuyerModal:FC<any>=({
    changeFromBuyerData,
    needByDateType,
    modal,
    closeModal
})=> {
  return (
     <Modal isOpen={modal} toggle={closeModal} size="lg">
      <ModalBody>
        <h5>Change To Buyer</h5>
        <Table>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Existing Value</th>
              <th>Changed Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Quantity</td>
              <td>{Number(changeFromBuyerData?.quantity)}{" "}{changeFromBuyerData?.uom}</td>
              <td>{Number(changeFromBuyerData?.changeRequestQuantity||changeFromBuyerData?.quantity)}{" "}{changeFromBuyerData?.uom}</td>
            </tr>
            <tr>
              <td>Need By Date</td>
              <td>{changeFromBuyerData?.needByDate}</td>
              <td>{changeFromBuyerData?.changeRequestNeedByDate||changeFromBuyerData?.needByDate}</td>
            </tr>
            <tr>
              <td>Unit Price</td>
              <td>{Number(changeFromBuyerData?.unitPrice)}</td>
              <td>{Number(changeFromBuyerData?.changeRequestUnitPrice||changeFromBuyerData?.unitPrice)}</td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  )
}

export default ChangeFromBuyerModal