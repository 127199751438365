import makeApiCall from "../master/axiosIndex";

const invoiceApis = {
  submitInvoice: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/submitInvoice",
      method: "post",
      data: data,
    }),
  fetchAllInvoices: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllInvoices",
      method: "get",
    }),
  fetchInvoice: (id: string): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchInvoice?invoiceNumber=${id}`,
      method: "get",
    }),
  fetchAllUserPendingApprovalInvoices: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllUserPendingApprovalInvoices",
      method: "get",
    }),
  amendmentInvoice: (invoiceNumber: string, data: any): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/amendmentInvoice/${invoiceNumber}`,
      method: "put",
      data: data,
    }),
  approveInvoice: (invoiceNumber: string): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/approveInvoice?invoiceNumber=${invoiceNumber}`,
      method: "patch",
    }),

  disapproveInvoice: (
    invoiceNumber: string,
    rejectedReason: string
  ): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/disapproveInvoice?invoiceNumber=${invoiceNumber}&rejectedReason=${rejectedReason}`,
      method: "patch",
    }),
  fetchAllInvoicesByOrderAndOrderLineNo: (
    orderId: any,
    orderLineId: any
  ): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchAllInvoicesByOrderAndOrderLineNo?orderNumber=${orderId}&orderLineNo=${orderLineId}`,
      method: "get",
    }),
  fetchAllSupplierInvoicesForDispatch: (): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchAllSupplierInvoicesForDispatch`,
      method: "get",
    }),
  fetchAllPaidInvoices: (): Promise<any> =>
    makeApiCall<any>({
      url: "/billing/fetchAllPaidInvoices",
      method: "get",
    }),
   fetchAllInvoicesForCreditDebitNote: (orgOrSupplierId: any): Promise<any> =>
    makeApiCall<any>({
      url: `/billing/fetchAllInvoicesForCreditDebitNote?orgOrSupplierId=${orgOrSupplierId}`,
      method: "get",
    }),
};

export default invoiceApis;
