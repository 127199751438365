import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card, Badge } from "reactstrap";
import Head from "../../layout/head/Head";
import ReactTooltip from 'react-tooltip';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../components/Component";
import PurchaseRequestAside from "./PurchaseRequestAside";
import { AgGridReact } from 'ag-grid-react';
import { GridApi, ColumnApi, GridReadyEvent, ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';



import DataTable, { TableColumn, TableStyles } from 'react-data-table-component';
import styled from 'styled-components';
interface RowData {
  id: number;
  materialId: string;
  category: string;
  materialName: string;
  description: string;
  uom: string;
  plannedStock: number;
  availableStock: number;
  materialRequested: string;
  qtyToBePurchased: string;
}


const AddNewPurchaseRequest: FC = () => {
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document.getElementsByClassName("nk-header")[0]?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document.getElementsByClassName("nk-header")[0]?.removeEventListener("click", headerClick);
    };
  }, []);

  const customStyles: TableStyles = {
    rows: {
      style: {
        minHeight: '72px', // Override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // Override the cell padding for head cells
        paddingRight: '8px',
        fontWeight: 'bold',
        whiteSpace: 'normal', // Wrap text for head cells
        wordWrap: 'break-word',
        minHeight: '56px',
        alignItems: 'center',
        display: 'flex',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // Override the cell padding for data cells
        paddingRight: '8px',
        whiteSpace: 'normal', // Wrap text for cells
        wordWrap: 'break-word',
        display: 'flex',
        alignItems: 'center',
        height: 'auto', // Auto height for cells
      },
    },
  };

  // Sample data
  const data: RowData[] = [
    {
      id: 1,
      materialId: "MAT001",
      category: "Electronics",
      materialName: "Resistor",
      description: "A resistor is a passive two-terminal electrical component that implements electrical resistance as a circuit element in various electronic circuits.",
      uom: "pcs",
      plannedStock: 1000,
      availableStock: 800,
      materialRequested: "",
      qtyToBePurchased: ""
    },
    {
      id: 1,
      materialId: "MAT001",
      category: "Electronics",
      materialName: "Resistor",
      description: "A resistor is a passive two-terminal electrical component that implements electrical resistance as a circuit element in various electronic circuits.",
      uom: "pcs",
      plannedStock: 1000,
      availableStock: 800,
      materialRequested: "",
      qtyToBePurchased: ""
    },
    // More data...
  ];

  const columns: TableColumn<RowData>[] = [
    {
      name: 'Material ID',
      selector: row => row.materialId,
      wrap: true,
    },
    {
      name: 'Category',
      selector: row => row.category,
      wrap: true,
    },
    {
      name: 'Material Name',
      selector: row => row.materialName,
      wrap: true,
    },
    {
      name: 'Description',
      selector: row => row.description,
      wrap: true,
      grow: 2, // Allow this column to take extra space
    },
    {
      name: 'UOM',
      selector: row => row.uom,
      wrap: true,
    },
    {
      name: 'Planned Stock',
      selector: row => row.plannedStock,
      wrap: true,
    },
    {
      name: 'Available Stock',
      selector: row => row.availableStock,
      wrap: true,
    },
    {
      name: 'Material Requested For Purchase',
      selector: row => row.materialRequested,
      wrap: true,
    },
    {
      name: 'Qty To Be Purchased',
      selector: row => row.qtyToBePurchased,
      wrap: true,
    },
  ];

  return (
    <>
      <Head title="User List - Profile" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""
                }`}
            >
              <PurchaseRequestAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => setSm(!sm)}></div>}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Create New Purchase Request</BlockTitle>
                    <BlockDes>
                      <p>Create a new purchase request</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block>
                <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                  <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    pagination
                    responsive
                    highlightOnHover
                    striped
                  />

                </div>
              </Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default AddNewPurchaseRequest;
