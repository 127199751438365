import makeApiCall from "./axiosIndex";
const contractorsApis = {
  fetchContractor: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchContractor" }),
  deleteContractorById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteContractorById?id=${id}`,
    }),
  createContractor: (data: any): Promise<any> =>
    makeApiCall<any>({ method: "post", url: "/seller/createContractor", data }),
  updateContractorById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({ method: "put", url: `/seller/updateContractorById?id=${id}`, data }),
  fetchContractorById: (id: any): Promise<any> =>
    makeApiCall<any>({ method: "get", url: `/seller/fetchCOntractorById?id=${id}` })
};

export default contractorsApis;
