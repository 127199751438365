import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";
import rolesApi from "../../../api/master/roles";
import Swal from "sweetalert2";
import orgsApis from "../../../api/master/org";

// const StyledNode = styled.div`
//   padding: 5px 3px;
//   border-radius: 3px;
//   display: inline-block;
//   border: 1px solid #2263b3;
//   min-width: 100px;
// `;

const StyledNode = styled.div<{ isRoot?: boolean }>`
  padding: 7px;
  border-radius: 3px;
  display: inline-block;
  border: ${(props) => props.isRoot ? "1px solid #398af1" : "1px solid #2263b3"};
  min-width: 100px;
  cursor: pointer;
   background: ${(props) =>
    props.isRoot
      ? "#398af1"
      : "#fff"};
  color: ${(props) => (props.isRoot ? "#fff" : "#000")};
`;

const OrgChart = ({nodesData,setSelectedNode}: any) => {
  const [data, setData] = useState<any>();
  const [roles, setRoles] = useState<any>([]);
  const [orgData, setOrgData] = useState<any>();

  const convertToTree = (nodes: any) => {
    const nodeMap = new Map();
    interface Node {
      id: number;
      name: string;
      children: Node[];
    }
    const root: Node[] = [];
    nodes.forEach((node: any) => {
      node.children = [];
      nodeMap.set(node.reportingRoleID, node);
    });

    nodes.forEach((node: any) => {
      if (node.reportingRoleID === node.reportingToRoleID) {
        root.push(node);
      } else {
        const parentNode = nodeMap.get(node.reportingToRoleID);
        if (parentNode) {
          parentNode.children.push(node);
        }
      }
    });

    return root;
  };
  const formatData = () => {
    const root = convertToTree(nodesData);

    setData({
      id: 0,
      name: orgData?.name,
      children: root,
    });
  };

  const handleClick = (node: any) => {
    setSelectedNode(()=>node);
  };

  const renderTree = (node: any) => (
    <TreeNode
      key={node?.reportingRoleID}
      label={
        <StyledNode onClick={() => handleClick(node)} style={{ cursor: "pointer" }}>
          {roles.find((obj: any) => obj.value == node?.reportingRoleID)?.label}
        </StyledNode>
      }
    >
      {node?.children && node?.children?.map((child: any) => renderTree(child))}
    </TreeNode>
  );


  const fetchRoles = async () => {
    try {
      const { data, status, message } = await rolesApi.fetchRolesData();

      if (status) {
        setRoles(
          data.map((obj: any) => ({
            label: obj.roleName,
            value: obj.roleID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };


  const fetchOrgData = async () => {
    try {
      const { data, status, message } = await orgsApis.fetchOrg();
      console.log("status", data);
      if (status) {
        const newData = data;
        const { docs } = newData;
        setOrgData(newData);
      }
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    fetchRoles();
    formatData();
    fetchOrgData();
  }, [nodesData]);

  return (
    <div>
      <div style={{textAlign:"end"}}>*Click on role to edit access</div>
    <div
      style={{
        maxWidth: "1400px",
        overflowX: "scroll",
        height: "90%",
        minHeight: "400px",
        margin: "auto",
        marginTop: "50px",
      }}
    >
      <Tree
        lineWidth={"2px"}
        lineColor={"#526484"}
        lineBorderRadius={"8px"}
        label={<StyledNode isRoot>{orgData?.name}</StyledNode>}
      >
        {data?.children &&
          data?.children?.map((child: any) => renderTree(child))}
      </Tree>
      {/* <Template>
        <p>Name: {data.name}</p>
      </Template> */}
    </div>
    </div>
  );
};

export default OrgChart;
