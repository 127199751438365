import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Head from './head/Head';
import Header from './header/Header';
import Footer from './footer/Foooter';
import AppRoot from './global/AppRoot';
import AppWrap from './global/AppWrap';

import FileManagerProvider from '../pages/app/file-manager/components/Context';

interface LayoutProps {
  title?: string;
  [key: string]: any; // For any additional props
}

const Layout: FC<LayoutProps> = ({ title, ...props }) => {
  return (
    <FileManagerProvider>
      <Head title={title || 'Loading'} />
      <AppRoot>
        <AppWrap>
          <Header fixed />
          <Outlet />
          <Footer />
        </AppWrap>
      </AppRoot>
    </FileManagerProvider>
  );
};

export default Layout;
