import makeApiCall from "./axiosIndex";

const GeneralLedgerApis = {
  fetchLedger: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchLedger" }),
  createLedger: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createLedger",
      data,
    }),
  updateLedgerById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/seller/updateLedgerById`,
      params: { id },
      data,
    }),
  fetchLedgerById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchLedgerById`,
      params: { id },
    }),
  deleteLedgerById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteLedgerById`,
      params: { id },
    }),
};

export default GeneralLedgerApis;
