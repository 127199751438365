import React, { useEffect, useState, useCallback } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Row, Col } from "reactstrap";
import FormSelect from "../../form-components/SelectComponent";
import usersApis from "../../../api/master/users";
interface SelectOptions {
  label: string;
  value: string;
}
const ContractorInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useFormContext();

  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);

  useEffect(() => {
    fetchStatesByCountry("1");
  }, []);

  const onStateChange = (e: any) => {
    getDistricts(e);
  };

  const fetchStatesByCountry = async (countryId: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
    } catch (error) {}
  };
  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const submitForm = (data: any) => {
    console.log("General Info Data:", data);
    props.next();
  };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <Col md="3">
          <FormInput
            name="referenceID"
            label="Internal ID"
            type="text"
            required={true}
            // maxLength={10}
            placeholder="Enter Internal ID"
          />
        </Col>
        <Col md="3">
          <FormInput
            name="companyName"
            label="Company Name"
            required={true}
            type="text"
            maxLength={50}
            placeholder="Enter Company Name"
            // disabled={action === "view"}
          />
        </Col>
        <Col md="3">
          <FormInput
            name="contactPerson"
            // disabled={action === "view"}
            label="Contact Person"
            required={true}
            type="text"
            maxLength={50}
            placeholder="Enter Contact Person"
          />
        </Col>
        <Col md="3">
          <FormInput
            name="email"
            // disabled={action === "view"}
            required={true}
            label="Email"
            type="email"
            placeholder="Enter Email"
            pattern={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/}
          />
        </Col>
        <Col md="3">
          <FormInput
            name="phoneNumber"
            label="Phone Number"
            // disabled={action === "view"}
            required={true}
            type="number"
            placeholder="Enter Phone Number"
            pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
          />
        </Col>
        {/* <Col md="3">
          <FormInput
            name="mailingAddressLine1"
            label="Business Address"
            // disabled={action === "view"}
            required={true}
            type="text"
            placeholder="Enter Business Address"
          />
        </Col> */}
        {/* <Col lg="4">
            <FormSelect
              name="country"
              label="Country"
              options={countries}
              required={true}
              placeholder="-Select-"
              onChange={(e) => {
                fetchStatesByCountry(e);
              }}
            />
          </Col> */}
          <Col  md="3">
            <FormSelect
              name="state"
              label="State"
              required={true}
              options={states}
              placeholder="Select State"
              onChange={(ev) => onStateChange(ev)}
            />
          </Col>
          <Col  md="3">
            <FormSelect
              name="district"
              label="District"
              options={districtOptions}
              required={true}
              placeholder="Select District"
            />
          </Col>
          <Col  md="3">
            <FormInput
              name="city"
              label="City"
              // required={true}
              // disabled={action === "view"}
              placeholder="Enter City"
              type="text"
            />
          </Col>
          <Col  md="3">
            <FormInput
              name="addressLine1"
              // disabled={action === "view"}
              label="Address Line 1"
              // required={true}
              placeholder="Enter Area"
            />
          </Col>
          <Col  md="3">
            <FormInput
              name="pincode"
              // disabled={action === "view"}
              label="Zip/Pincode"
              required={true}
              type="number"
              placeholder="Enter Zip or pin code"
            />
          </Col>
        <Col  md="3">
          <FormInput
            name="yearsInBusiness"
            // disabled={action === "view"}
            label="Years in Business"
            type="text"
            placeholder="Enter Years in Business"
          />
        </Col>
        <Col md="3">
          <FormInput
            name="references"
            label="References"
            type="text"
            // disabled={action === "view"}
            placeholder="Enter References"
          />
        </Col>
        </Row>
      <Row className="gy-4 mt-1">
        <Col>
          <Button color="primary" type="submit">
            Next
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default React.memo(ContractorInformation);
