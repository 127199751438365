import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import Head from '../../layout/head/Head';
import Content from "../../layout/content/Content";
import { Card } from 'reactstrap';
import DispatchAside from './dispatchAside';
import DispatchComponent from './dispatchComponent';

function ShowDispatchDetailsWrapper() {
  let { id }: any = useParams();
  const [sm, setSm] = useState(false);

  return (
    <>
      <Head title="Material Invoice" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg 
                ${sm ? "content-active" : ""}
              `}
            >
              <DispatchAside updateSm={setSm} sm={sm} />
            </div>
              <DispatchComponent dispatchNumber={id} previousPath={"/view-dispatch-list"}/>
          </div>
        </Card>
      </Content>
    </>
  );
}

export default ShowDispatchDetailsWrapper