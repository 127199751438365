import React, { useState, useEffect, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import menu from './MenuData';
import { MenuItemProps, MenuSubProps, MenuProps } from './MenuInterfaces';

const MenuItem: FC<MenuItemProps> = ({ item, headActive, sidebarToggle }) => {
  const { pathname } = useLocation();
  const { text, link, newTab, badge, subMenu } = item;

  // Recursively check if the current item or any of its sub-menu items is active
  const isActive = (item: any) => {
    if (item.link && process.env.PUBLIC_URL + item.link === pathname) {
      return true;
    }
    if (item.subMenu) {
      return item.subMenu.some((sub: any) => isActive(sub));
    }
    return false;
  };

  const linkClass = `nk-menu-link ${subMenu ? 'nk-menu-toggle' : ''}`;

  return (
    <li className={`nk-menu-item ${subMenu ? 'has-sub' : ''} ${isActive(item) ? 'active current-page' : ''} ${headActive ? 'active current-page' : ''}`}>
      {newTab ? (
        <a href={link ? process.env.PUBLIC_URL + link : '#'} target="_blank" rel="noopener noreferrer" className={linkClass}>
          {text}{badge && <span className="nk-menu-badge">{badge}</span>}
        </a>
      ) : subMenu ? (
        <React.Fragment>
          <a
            href="#toggle"
            className="nk-menu-link nk-menu-toggle"
            onClick={(ev) => ev.preventDefault()}
          >
            <span className="nk-menu-text">{text}</span>
          </a>
          <MenuSub sidebarToggle={sidebarToggle} subMenu={subMenu} />
        </React.Fragment>
      ) : (
        <Link className="nk-menu-link" to={process.env.PUBLIC_URL + link}>
          <span className="nk-menu-text">{text}</span>
          {badge && <span className="nk-menu-badge">{badge}</span>}
        </Link>
      )}
    </li>
  );
};

const MenuSub: FC<MenuSubProps> = ({ subMenu, sidebarToggle }) => (
  <ul className="nk-menu-sub">
    {subMenu.map((sub, index) => (
      <MenuItem key={index} item={sub} headActive={false} sidebarToggle={sidebarToggle} />
    ))}
  </ul>
);

const Menu: FC<MenuProps> = ({ sidebarToggle }) => {
  const { pathname } = useLocation();
  const [activeHead, setActiveHead] = useState('');

  useEffect(() => {
    const updateActiveHead = () => {
      const activeItem = menu.find(item => 
        item.link === pathname || 
        (item.subMenu && item.subMenu.some(sub => process.env.PUBLIC_URL + sub.link === pathname))
      );
      setActiveHead(activeItem ? activeItem.text : 'Dashboards');
    };

    updateActiveHead();
    window.addEventListener('popstate', updateActiveHead);
    return () => window.removeEventListener('popstate', updateActiveHead);
  }, [pathname]);

  return (
    <ul className="nk-menu nk-menu-main ui-s2">
      {menu.map((item, index) => (
        <MenuItem key={index} item={item} headActive={item.text === activeHead} sidebarToggle={sidebarToggle} />
      ))}
    </ul>
  );
};

export default Menu;
