import makeApiCall from "./axiosIndex";
const materialsApis = {
  fetchProduct: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchProduct" }),
  deleteProductById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteProductById?id=${id}`
    }),
    createProduct: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createProduct",
      data,
    }),
    updateProductById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: "/seller/updateProductById",
      params: { id: id },
      data,
    }),
    fetchProductById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/seller/fetchProductById",
      params: { id },
    }),
    deletePrefferedSuppliersById: (id: any): Promise<any> =>
      makeApiCall<any>({
        method: "delete",
        url: `/seller/deletePrefferedSuppliersById`,
        params: { id },
      }),
};

export default materialsApis;
