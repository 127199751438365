import makeApiCall from "./axiosIndex";

const consultantsApis= {
  fetchConsultants: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchConsultant" }),
  deleteConsultantById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteConsultantById?id=${id}`,
    }),
  createConsultant: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createConsultant",
      data,
    }),
  updateConsultantById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/seller/updateConsultantById`,
      params: { id },
      data,
    }),
  fetchConsultantById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchConsultantById`,
      params: { id },
    }),
};

export default consultantsApis;
