import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Head from './head/Head';

interface LayoutProps {
  title?: string;
  [key: string]: any; // For any additional props
}

const Layout: FC<LayoutProps> = ({ title, ...props }) => {
  return (
    <>
      <Head title={title || 'Loading'} />
      <div className="nk-app-root">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
