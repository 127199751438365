import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere

interface CNRequestAsideProps {
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const CreditDebitNoteAside: FC<CNRequestAsideProps> = ({ updateSm, sm }) => {
   
    const location = useLocation();

    useEffect(() => {
        sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{fontSize: 14 }}>Credit/Debit Note</h6>
                   
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">

                    {/* <li onClick={() => updateSm(false)}>
                        <Link to="/credit-debit-note-new" className={isActive(`${process.env.PUBLIC_URL}/credit-debit-note-new`)}>
                            <Icon name="check-fill-c" />
                            <span>Create New</span>
                        </Link>
                    </li> */}
                    <li onClick={() => updateSm(false)}>
                        <Link to="/pending-approval-credit-debit-notes" className={isActive(`${process.env.PUBLIC_URL}/pending-approval-credit-debit-notes`)}>
                            <Icon name="check-fill-c" />
                            <span>Pending Approval</span>
                        </Link>
                    </li>

                    <li onClick={() => updateSm(false)}>
                        <Link to="/all-credit-debit-notes" className={isActive(`${process.env.PUBLIC_URL}/all-credit-debit-notes`)}>
                            <Icon name="eye-fill" />
                            <span>View Notes</span>
                        </Link>
                    </li>


                </ul>
            </div>
        </div>
    );
};

export default CreditDebitNoteAside;
