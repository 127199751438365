import React, { useState, createContext, useContext, useEffect, ReactNode } from 'react';
import classNames from 'classnames';

interface Theme {
  main: string;
  sidebarVisibility: boolean;
  sidebarMobile: boolean;
  header: string;
  skin: string;
}

interface ThemeUpdate {
  uistyle: (value: string) => void;
  sidebarVisibility: (e: React.MouseEvent) => void;
  sidebarHide: (e: React.MouseEvent) => void;
  header: (value: string) => void;
  skin: (value: string) => void;
  reset: (e: React.MouseEvent) => void;
}

const ThemeContext = createContext<Theme | undefined>(undefined);
const ThemeUpdateContext = createContext<ThemeUpdate | undefined>(undefined);

export function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}

export function useThemeUpdate() {
  const context = useContext(ThemeUpdateContext);
  if (!context) {
    throw new Error('useThemeUpdate must be used within a ThemeProvider');
  }
  return context;
}

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const defaultTheme: Theme = {
    main: 'default',
    sidebarVisibility: false,
    sidebarMobile: false,
    header: 'white',
    skin: 'light',
  };

  const [theme, setTheme] = useState<Theme>(defaultTheme);

  const themeUpdate: ThemeUpdate = {
    uistyle: (value) => setTheme((prev) => ({ ...prev, main: value })),
    sidebarVisibility: (e) => setTheme((prev) => ({ ...prev, sidebarVisibility: !prev.sidebarVisibility })),
    sidebarHide: (e) => setTheme((prev) => ({ ...prev, sidebarVisibility: false })),
    header: (value) => setTheme((prev) => ({ ...prev, header: value })),
    skin: (value) => setTheme((prev) => ({ ...prev, skin: value })),
    reset: (e) => setTheme(defaultTheme),
  };

  const bodyClass = classNames({
    'nk-body bg-lighter npc-invest has-touch nk-nio-theme': true,
  });

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.className = bodyClass;
    }
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.classList.toggle('ui-default', theme.main === 'default');
      body.classList.toggle('ui-clean', theme.main === 'clean');
      body.classList.toggle('ui-shady', theme.main === 'shady');
      body.classList.toggle('ui-softy', theme.main === 'softy');
      body.classList.toggle('dark-mode', theme.skin === 'dark');
      body.classList.toggle('nav-shown', theme.sidebarVisibility);
    }
  }, [theme]);

  useEffect(() => {
    const handleMobileSidebar = () => {
      if (window.innerWidth < 992) {
        setTheme((prev) => ({ ...prev, sidebarMobile: true }));
      } else {
        setTheme((prev) => ({ ...prev, sidebarMobile: false, sidebarVisibility: false }));
      }
    };

    handleMobileSidebar();
    window.addEventListener('resize', handleMobileSidebar);
    return () => {
      window.removeEventListener('resize', handleMobileSidebar);
    };
  }, []);

  return (
    <ThemeContext.Provider value={theme}>
      <ThemeUpdateContext.Provider value={themeUpdate}>{children}</ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
