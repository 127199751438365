import makeApiCall from "./axiosIndex";
const quotationApis = {
  fetchList: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/list`,
    }),
  fetchCounts: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/getCounts`,
    }),
  quotedList: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/quotedList`,
    }),
  requestedList: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/requestedList`,
    }),
  fetchPendingApprovals: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-buyer/pendingApprovals`,
    }),
  approve: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/quotation-buyer/approveSupplierQuote`,
      params: { quotationId },
    }),
  reject: (quotationId: any, reason: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/quotation-buyer/rejectSupplierQuote`,
      params: { quotationId, reason },
    }),
  latestByQuotationId: (quotationId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/quotation-seller/latestByQuotationId`,
      params: { quotationId },
    }),
  submitSupplierQuote: (data: any): Promise<any> =>
    makeApiCall<any>({
      url: "/quotation-seller/quote",
      method: "post",
      data: data,
    }),
    fetchQuotedQuotationId: (quotationId: any): Promise<any> =>
      makeApiCall<any>({
        method: "get",
        url: `/quotation-seller/fetchQuotedQuotationId`,
        params: { quotationId },
      }),
      quoteNewVersion: (data: any) =>
        makeApiCall({
          url: "/quotation-seller/quoteNewVersion",
          method: "post",
          data,
        }),
        regretSupplier: (quotationId: any, supplierId: any): Promise<any> =>
          makeApiCall<any>({
            method: "patch",
            url: `/quotation-buyer/regretSupplier`,
            params: { quotationId,supplierId },
          }),
};

export default quotationApis;
