import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { Icon, Col, Row, Button, RSelect } from "../../../components/Component";
import FormSelect from "../../form-components/SelectComponent";
import misc from "../../../api/misc/dropDowns";
interface SelectOptions {
  label: string;
  value: string;
}
const PricingInformation: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    props.next();
  };
  const [gstData, setgstData] = useState<SelectOptions[]>([]);

  const fetchGSTSlabs = async () => {
    try {
      const { data } = await misc.getTaxSlabs();
      if (data) {
        setgstData(
          data.map((obj: any) => ({
            label: obj.label,
            value: obj.value.toString(),
          }))
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchGSTSlabs();
  },[]);
  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <div className="col-md-4">
          <FormInput
            name="unitPrice"
            label="Unit Price"
            type="number"
            placeholder="Enter Unit Price"
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="hsnCode"
            label="HSN Code"
            type="text"
            placeholder="Enter HSN Code"
          />
        </div>
        <div className="col-md-4">
          <FormSelect
            name="gst"
            label="GST"
            options={gstData}
            placeholder="Enter GST"
          />
        </div>
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default React.memo(PricingInformation);
