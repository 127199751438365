import React, { useEffect } from 'react';
import Icon from '../../components/icon/Icon';
import classNames from 'classnames';
import { NavLink, Link } from 'react-router-dom';

interface MenuItemProps {
  icon?: string;
  link: string;
  text: string;
  sub?: any[]; // Adjust type according to the structure of sub items
  newTab?: boolean;
  sidebarToggle: (e: React.MouseEvent<HTMLLIElement>) => void;
  mobileView: boolean;
  badge?: string;
  [key: string]: any; // For any additional props
}

interface MenuSubProps {
  sub: any[]; // Adjust type according to the structure of sub items
  sidebarToggle: (e: React.MouseEvent<HTMLLIElement>) => void;
  mobileView: boolean;
  style?: React.CSSProperties;
  [key: string]: any; // For any additional props
}

interface MobileMenuProps {
  data: any[]; // Adjust type according to the structure of data items
  sidebarToggle: (e: React.MouseEvent<HTMLLIElement>) => void;
  mobileView: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, link, text, sub, newTab, sidebarToggle, mobileView, badge, ...props }) => {
  const currentUrl = window.location.pathname !== undefined ? window.location.pathname : null;

  const toggleActionSidebar = (e: React.MouseEvent<HTMLLIElement>) => {
    if (!sub && !newTab && mobileView) {
      sidebarToggle(e);
    }
  };

  const menuHeight = (el: HTMLCollectionOf<Element>) => {
    const elementsArray = Array.from(el) as HTMLElement[]; // Convert HTMLCollection to array
    const totalHeight = elementsArray.reduce((sum, element) => {
      const margin =
        parseInt(window.getComputedStyle(element).marginTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(element).marginBottom.slice(0, -2));
      const padding =
        parseInt(window.getComputedStyle(element).paddingTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(element).paddingBottom.slice(0, -2));
      return sum + element.clientHeight + margin + padding;
    }, 0);
    return totalHeight;
  };

  const makeParentActive = (el: HTMLElement, childHeight: number) => {
    const element = el.parentElement?.parentElement?.parentElement as HTMLElement;
    const wrap = el.parentElement?.parentElement as HTMLElement;
    if (element?.classList[0] === 'nk-menu-item') {
      element.classList.add('active');
      const subMenuHeight = menuHeight(el.parentNode?.children as HTMLCollectionOf<Element>);
      if (wrap) wrap.style.height = subMenuHeight + childHeight - 50 + 'px';
      makeParentActive(element, subMenuHeight);
    }
  };

  useEffect(() => {
    const element = document.getElementsByClassName('nk-menu-item active current-page');
    const arrayElement = Array.from(element) as HTMLElement[]; // Convert HTMLCollection to array

    arrayElement.forEach((dom) => {
      const parentElement = dom.parentElement?.parentElement?.parentElement as HTMLElement;
      if (parentElement?.classList[0] === 'nk-menu-item') {
        parentElement.classList.add('active');
        const subMenuHeight = menuHeight(dom.parentNode?.children as HTMLCollectionOf<Element>);
        if (dom.parentElement?.parentElement) dom.parentElement.parentElement.style.height = subMenuHeight + 'px';
        makeParentActive(dom.parentElement?.parentElement?.parentElement as HTMLElement, subMenuHeight);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuToggle = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const self = e.target as HTMLElement;
    const closestToggle = self.closest('.nk-menu-toggle') as HTMLElement;
    const parent = closestToggle?.parentElement as HTMLElement;
    const subMenu = closestToggle?.nextSibling as HTMLElement;
    const subMenuItem = subMenu?.childNodes as NodeListOf<ChildNode>;
    const parentSiblings = parent?.parentElement?.childNodes as NodeListOf<ChildNode>;
    const parentMenu = parent?.closest('.nk-menu-wrap') as HTMLElement;
    const subMenuHeight = menuHeight(subMenuItem as unknown as HTMLCollectionOf<Element>);

    const getParents = (el: Element, parentSelector: string = '.nk-menu') => {
      const parents: HTMLElement[] = [];
      let p = el.parentNode as HTMLElement;
      while (p && !p.matches(parentSelector)) {
        const o = p;
        parents.push(o);
        p = o.parentNode as HTMLElement;
      }
      parents.push(document.querySelector(parentSelector) as HTMLElement);
      return parents;
    };
    const parentMenus = getParents(self);

    if (!parent?.classList.contains('active')) {
      parentSiblings?.forEach((sibling) => {
        const siblingElement = sibling as HTMLElement;
        siblingElement.classList.remove('active');
        const siblingChildren = siblingElement.childNodes[1] as HTMLElement;
        if (siblingChildren) siblingChildren.style.height = '0';
      });

      if (parentMenu && !parentMenu.classList.contains('sub-opened')) {
        parentMenu.classList.add('sub-opened');
        parentMenus.forEach((menu) => {
          const menuElement = menu as HTMLElement;
          if (menuElement?.classList.contains('nk-menu-wrap')) {
            menuElement.style.height = subMenuHeight + menuElement.clientHeight + 'px';
          }
        });
      }
      parent?.classList.add('active');
      subMenu.style.height = subMenuHeight + 'px';
    } else {
      parent.classList.remove('active');
      if (parentMenu) {
        parentMenu.classList.remove('sub-opened');
        parentMenus.forEach((menu) => {
          const menuElement = menu as HTMLElement;
          if (menuElement?.classList.contains('nk-menu-wrap')) {
            menuElement.style.height = menuElement.clientHeight - subMenuHeight + 'px';
          }
        });
      }
      subMenu.style.height = '0';
    }
  };

  const menuItemClass = classNames({
    'nk-menu-item': true,
    'has-sub': sub,
    'active current-page': currentUrl === process.env.PUBLIC_URL + link,
  });

  return (
    <li className={menuItemClass} onClick={(e) => toggleActionSidebar(e)}>
      {newTab ? (
        <Link to={`${process.env.PUBLIC_URL + link}`} target="_blank" rel="noopener noreferrer" className="nk-menu-link">
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text">{text}</span>
        </Link>
      ) : (
        <NavLink
          to={`${process.env.PUBLIC_URL + link}`}
          className={`nk-menu-link${sub ? ' nk-menu-toggle' : ''}`}
          onClick={sub ? menuToggle : undefined}
        >
          {icon ? (
            <span className="nk-menu-icon">
              <Icon name={icon} />
            </span>
          ) : null}
          <span className="nk-menu-text">{text}</span>
          {badge && <span className="nk-menu-badge">{badge}</span>}
        </NavLink>
      )}
      {sub ? (
        <div className="nk-menu-wrap">
          <MenuSub sub={sub} sidebarToggle={sidebarToggle} mobileView={mobileView} />
        </div>
      ) : null}
    </li>
  );
};

const MenuSub: React.FC<MenuSubProps> = ({ sub, sidebarToggle, mobileView, ...props }) => {
  return (
    <ul className="nk-menu-sub" style={props.style}>
      {sub.map((item, i) => (
        <MenuItem
          key={i}
          link={item.link}
          icon={item.icon}
          text={item.text}
          sub={item.subMenu}
          badge={item.badge}
          newTab={item.newTab}
          sidebarToggle={sidebarToggle}
          mobileView={mobileView}
        />
      ))}
    </ul>
  );
};

const MobileMenu: React.FC<MobileMenuProps> = ({ data, sidebarToggle, mobileView }) => {
  return (
    <ul className="nk-menu nk-menu-main ui-s2">
      {data.map((item) => (
        <MenuItem
          key={item.text}
          link={item.link}
          icon={item.icon}
          text={item.text}
          sub={item.subMenu}
          badge={item.badge}
          sidebarToggle={sidebarToggle}
          mobileView={mobileView}
        />
      ))}
    </ul>
  );
};

export default MobileMenu;
