import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Row, Col, Alert } from "reactstrap";
import FormCheckbox from "../../form-components/CheckboxComponent";
import contractorsApis from "../../../api/master/contractors";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ConflictResolution: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    console.log("Final form data:", data);
    createContractor(data);
    // Add your API submission logic here
  };

  const createContractor = async (data: any) => {
    const response = await contractorsApis.createContractor(data);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "Contractor has been created", "success");
      navigate(`/general-contractors`);
    } else {
      let errorMessage = "An error occurred";
      const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };

  useEffect(() => {
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
  }, []);

  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col md="6">
            <FormInput
              name="emergencyContactPerson"
              label="Contact Person"
              type="text"
              placeholder="Enter Contact Person"
              // disabled={action === "view"}
            />
          </Col>
          <Col md="6">
            <FormInput
              name="emergencyContactNumber"
              // disabled={action === "view"}
              label="Emergency Contact Number"
              type="text"
              placeholder="Enter Emergency Contact Information"
            />
          </Col>
          <Col md="6">
            <FormInput
              name="emergencyProcedures"
              label="Emergency Procedures"
              // disabled={action === "view"}
              type="textarea"
              placeholder="Enter Emergency Procedures"
            />
          </Col>
          <Col md="6">
            <FormInput
              name="docsUpload3"
              label="Docs Upload"
              // disabled={action === "view"}
              type="file"
              placeholder="Docs Upload"
            />
          </Col>
          <Col md="6">
            <FormInput
              name="disputeResolutionProcess"
              // disabled={action === "view"}
              label="Dispute Resolution Process"
              type="textarea"
              placeholder="Enter Dispute Resolution Process"
            />
          </Col>
          <Col md="6">
            <FormInput
              name="docsUpload5"
              // disabled={action === "view"}
              label="Docs Upload"
              type="file"
              placeholder="Docs Upload"
            />
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Submit Form
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default React.memo(ConflictResolution);
