import { MenuItem } from "./MenuInterfaces";
const menu: MenuItem[] = [
  {
    text: "Dashboard",
    link: "/dashboard",
  },
  {
    text: "Masters",
    subMenu: [
      {
        text: "Organisation",
        link: "/organisation",
      },
      {
        text: "Manufacturing Locations",
        link: "/sites",
      },
      {
        text: "Departments",
        link: "/departments",
      },
      {
        text: "Roles",
        active: false,
        subMenu: [
          {
            text: "Roles",
            link: "/roles",
          },
          {
            text: "Role Heirarchy",
            link: "/reporting-heirarchy",
          },
        ],
      },
      {
        text: "Material Category",
        link: "/supplier-category",
      },
      {
        text: "Materials",
        link: "/materails",
      },
      {
        text: "Users",
        link: "/users",
      },

      {
        text: "Approval Heirarchy",
        link: "/approval-heirarchy",
      },

      {
        text: "Cost Center",
        link: "/cost-center",
      },
      {
        text: "General Ledger",
        link: "/general-ledger",
      },
    ],
  },
  {
    text: "Procure to Pay",
    subMenu: [
      {
        text: "Budget",
        link: "/add-budget",
      },
      {
        text: "Purchase Request",
        link: "/add-new-purchase-request",
      },
      {
        text: "Quotations",
        link: "/view-rfqs",
      },
      {
        text: "Forward Auction",
        link: "/forward-auction-new",
      },
      {
        text: "Reverse Auction",
        link: "reverse-auction-new",
      },
      {
        text: "Purchase Orders",
        link: "/view-purchase-order",
      },
      {
        text: "Invoices",
        link: "/view-invoice-list",
      },
      {
        text: "Credit/Debit Note",
        link: "/all-credit-debit-notes",
      },
      {
        text: "Dispatch",
        link: "/view-dispatch-list",
      },
      {
        text: "Payments",
        link: "/view-payments",
      },
      // {
      //   text: "Inward Goods",
      //   link: "/pricing-table",
      // },
      // {
      //   text: "Returns",
      //   link: "/image-gallery",
      // },
      // {
      //   text: "Rate Contracts",
      //   link: "/pages/regular-v2",
      // },
    ],
  },
  {
    text: "Market Place",
    subMenu: [
      {
        text: "Quotations",
        // link: "/analytics-rfq",
      },
      {
        text: "Forward Auction",
        // link: "/forward-auction-new",
      },
      {
        text: "Reverse Auction",
        // link: "reverse-auction-new",
      },
      {
        text: "Registered Suppliers",
        // link: "reverse-auction-new",
      },
    ],
  },
  {
    text: "Inventory",
    subMenu: [
      {
        text: "Material Issue",
        active: false,
      },
      {
        text: "Utilization Status",
        active: false,
      },
      {
        text: "Damaged",
        link: "/_blank",
      },
      {
        text: "Inventory",
        link: "",
      },
    ],
  },
  // {
  //   text: "Work Schedules",
  //   link: "",

  // },
  // {
  //   text: "Construction Management",
  //   link: ""
  // },
];
export default menu;
