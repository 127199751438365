import makeApiCall from "./axiosIndex";

const sites = {
  fetchSites: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchManufacturingLocation" }),
  fetchSiteWithBlockById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchManufacturingLocationById`,
      params: { id },
    }),

  createSite: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createManufacturingLocation",
      data,
    }),
  updateSitesById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/seller/updateManufacturingLocationById`,
      params: { id },
      data,
    }),

  getDistrictsByState: (stateId: number): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/districtByState`,
      params: { stateId },
    }),
  deleteSiteByID: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteManufacturingLocationById`,
      params: { id },
    }),
    getCountrys: (): Promise<any> =>
      makeApiCall<any>({ method: "get", url: "/seller/countries" }),
};

export default sites;
