import React, { useState, useEffect, useMemo, FC, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  RSelect,
  UserAvatar,
  TooltipComponent,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import axios from "../../../axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import DatePicker from "react-datepicker";
import { getDateStructured } from "../../../utils/Utils";
import supplierApis from "../../../api/master/supplier";
import { useNavigate } from "react-router-dom";
import contractorsApis from "../../../api/master/contractors";

import FormInput from "../../form-components/InputComponent";
import FormatDate from "../DateFormater";

interface SelectOptions {
  label: string;
  value: string;
}
const ViewContractorDetails: FC = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { siteId } = useParams();
  const [data, setData]: any = useState();
  const [modalGI, setGIModal] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [submitEditData, setSubmitEditData] = useState(false);
  const navigate = useNavigate();
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");

  const [formData, setFormData]: any = useState({
    companyName: "",
    contactPerson: "",
    email: "",
    phoneNumber: "",
    mailingAddressLine1: "",
    businessDescription: "",
    yearsInBusiness: "",
    references: "",
    regulatoryCompliance: "",
    insuranceCoverage: "",
    docsUpload: "",
    projectRequirements: "",
    specifications: "",
    // docsUpload: "",
    billingRates: "",
    // docsUpload: "",
    contractTerms: "",
    insuranceRequirements: "",
    // docsUpload: "",
    qualityAssurancePlan: "",
    inspectionAndAcceptanceCriteria: "",
    docsUpload4: "",
    environmentalCompliance: "",
    wasteManagementProcedures: "",
    docsUpload2: "",
    emergencyContactPerson: "",
    emergencyContactNumber: "",
    emergencyProcedures: "",
    docsUpload3: "",
    disputeResolutionProcess: "",
    docsUpload5: "",
  });

  const submitForm = () => {
    let submitData = {
      ...formData,
    };
    console.log("submitData", submitData);
    // setData(submitData);
    submitUpdates(submitData);
    setGIModal(false);
  };

  const onInputChange = (e: any) => {
    console.log("e.target.name", e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [categories, setCategories] = useState<SelectOptions[]>([
    { label: "Category1", value: "Category1" },
    { label: "Category2", value: "Category2" },
  ]);

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = siteId;
    console.log("id", id);
    if (id !== undefined || null || "") {
      fetchContractorData(id);
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [siteId]);

  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
  };

  const submitUpdates = async (submitData: any) => {
    const { status, message } = await contractorsApis.updateContractorById(
      siteId,
      submitData
    );
    if (status) {
      Swal.fire("Updated!", "Contractor has been updated", "success");
      fetchContractorData(siteId);
      //   navigate(`/general-contractors`);
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const fetchContractorData = async (id: any) => {
    try {
      const { data, status, message } =
        await contractorsApis.fetchContractorById(id);
      console.log("status", data);
      if (status) {
        console.log("status", data);
        const newData = data;
        setData(newData); // Update data with fetched response
        setFormData({
          companyName: newData.companyName,
          contactPerson: newData.contactPerson,
          email: newData.email,
          phoneNumber: newData.phoneNumber,
          mailingAddressLine1: newData.mailingAddressLine1,
          businessDescription: newData.businessDescription,
          yearsInBusiness: newData.yearsInBusiness,
          references: newData.references,
          regulatoryCompliance: newData.regulatoryCompliance,
          insuranceCoverage: newData.insuranceCoverage,
          docsUpload: newData.docsUpload,
          projectRequirements: newData.projectRequirements,
          specifications: newData.specifications,
          // docsUpload: newData.docsUpload,
          billingRates: newData.billingRates,
          // docsUpload: newData.docsUpload,
          contractTerms: newData.contractTerms,
          insuranceRequirements: newData.insuranceRequirements,
          // docsUpload: newData.
          qualityAssurancePlan: newData.qualityAssurancePlan,
          inspectionAndAcceptanceCriteria:
            newData.inspectionAndAcceptanceCriteria,
          docsUpload4: newData.docsUpload4,
          environmentalCompliance: newData.environmentalCompliance,
          wasteManagementProcedures: newData.wasteManagementProcedures,
          docsUpload2: newData.docsUpload2,
          emergencyContactPerson: newData.emergencyContactPerson,
          emergencyContactNumber: newData.emergencyContactNumber,
          emergencyProcedures: newData.emergencyProcedures,
          docsUpload3: newData.docsUpload3,
          disputeResolutionProcess: newData.disputeResolutionProcess,
          docsUpload5: newData.docsUpload5,
        });
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      <Head title="Contractor Details"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Contractor Details -{" "}
                    <strong className="text-primary small">
                      {data["contactPerson"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      {/* <li>
                                                Site ID: <span className="text-base">{data.siteID}</span>
                                            </li> */}
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  {/* {submitEditData && (
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                      style={{ marginRight: "10px" }}
                      onClick={() => submitUpdates()}
                    >
                      <Icon name="check-round-cut"></Icon>
                      <span>Submit Updates</span>
                    </Button>
                  )} */}

                  {/* <Button outline className="bg-white d-none d-sm-inline-flex" style={{ marginRight: "10px" }} onClick={() => openEditModal()}>
                                        <Icon name="edit-alt-fill"></Icon>
                                        <span>Edit</span>
                                    </Button> */}
                  <Link to={`${process.env.PUBLIC_URL}/general-contractors`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  lg="5"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Contractor Information</BlockTitle>
                        <p>Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("contractorInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Company Name</div>
                          <div className="data-value">{data.companyName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contact Person</div>
                          <div className="data-value">{data.contactPerson}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email Address</div>
                          <div className="data-value">{data.email}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Phone Number</div>
                          <div className="data-value">{data.phoneNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Mailing Address</div>
                          <div className="data-value">
                            {data.mailingAddressLine1}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Contractor Profile</BlockTitle>
                        <p>PAN, GSTIN etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("contractorProfile")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Business Description</div>
                          <div className="data-value">
                            {data.businessDescription}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Years in Business</div>
                          <div className="data-value">
                            {data.yearsInBusiness}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">References</div>
                          <div className="data-value">{data.references}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Compliance and Certifications
                        </BlockTitle>
                        <p>Some more Other details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal("complianceCertifications")
                          }
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Regulatory Compliance
                          </div>
                          <div className="data-value">
                            {data.regulatoryCompliance ? (
                              <Icon
                                name="check-circle-fill"
                                className="text-success"
                              />
                            ) : (
                              <Icon
                                name="cross-circle-fill"
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Licenses and Permits</div>
                          <div className="data-value">
                            {data.licensesAndPermits ? (
                              <Icon
                                name="check-circle-fill"
                                className="text-success"
                              />
                            ) : (
                              <Icon
                                name="cross-circle-fill"
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Insurance Coverage</div>
                          <div className="data-value">
                            {data.insuranceCoverage ? (
                              <Icon
                                name="check-circle-fill"
                                className="text-success"
                              />
                            ) : (
                              <Icon
                                name="cross-circle-fill"
                                className="text-danger"
                              />
                            )}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Scope of Wors</BlockTitle>
                        <p>Basic Contact Information Details etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ScopeWors")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Project Requirements</div>
                          <div className="data-value">
                            {data.projectRequirements}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Specifications</div>
                          <div className="data-value">
                            {data.specifications}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Billing and Payment Terms
                        </BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("BillingPaymentTerms")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Payment Terms</div>
                          <div className="data-value">{data.paymentTerms}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Billing Rates</div>
                          <div className="data-value">{data.billingRates}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Contractual Agreements</BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ContractualAgreements")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contract Terms</div>
                          <div className="data-value">{data.contractTerms}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Insurance Requirements
                          </div>
                          <div className="data-value">
                            {data.insuranceRequirements}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Quality Assurance and Inspection
                        </BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal("QualityAssuranceInspection")
                          }
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Quality Assurance Plan
                          </div>
                          <div className="data-value">
                            {data.qualityAssurancePlan}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Inspection and Acceptance Criteria
                          </div>
                          <div className="data-value">
                            {data.inspectionAndAcceptanceCriteria}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload4}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Environmental and Sustainability Considerations
                        </BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal(
                              "EnvironmentalSustainabilityConsiderations"
                            )
                          }
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Environmental Compliance
                          </div>
                          <div className="data-value">
                            {data.environmentalCompliance}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Waste Management Procedures
                          </div>
                          <div className="data-value">
                            {data.wasteManagementProcedures}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload2}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">
                          Emergency Response and Contingency Plans
                        </BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            openEditModal("EmergencyResponseContingencyPlans")
                          }
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contact Person</div>
                          <div className="data-value">
                            {data.emergencyContactPerson}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Emergency Contact Number
                          </div>
                          <div className="data-value">
                            {data.emergencyContactNumber}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Emergency Procedures</div>
                          <div className="data-value">
                            {data.emergencyProcedures}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload3}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Conflict Resolution</BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ConflictResolution")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">
                            Dispute Resolution Process
                          </div>
                          <div className="data-value">
                            {data.disputeResolutionProcess}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Docs Upload</div>
                          <div className="data-value">{data.docsUpload5}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>

              {modalEditBlockName === "contractorInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Contractor Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="companyName">
                              Company Name
                            </label>
                            <input
                              type="text"
                              id="companyName"
                              className="form-control"
                              name="companyName"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.companyName}
                              placeholder="Enter Company Name"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="contactPerson"
                            >
                              Contact Person
                            </label>
                            <input
                              type="text"
                              id="contactPerson"
                              className="form-control"
                              name="contactPerson"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.contactPerson}
                              placeholder="Enter Contact Person"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="email">
                              email
                            </label>
                            <input
                              type="text"
                              id="email"
                              className="form-control"
                              name="email"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.email}
                              placeholder="Enter Email"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="phoneNumber">
                              Phone Number
                            </label>
                            <input
                              type="number"
                              id="phoneNumber"
                              className="form-control"
                              name="phoneNumber"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.phoneNumber}
                              placeholder="Enter Phone Number"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="mailingAddressLine1"
                            >
                              Mailing Address
                            </label>
                            <input
                              type="text"
                              id="mailingAddressLine1"
                              className="form-control"
                              name="mailingAddressLine1"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.mailingAddressLine1}
                              placeholder="Enter Mailing Address"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "contractorProfile" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Contractor Profile </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="businessDescription"
                            >
                              Business Description
                            </label>
                            <input
                              type="text"
                              id="businessDescription"
                              className="form-control"
                              name="businessDescription"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.businessDescription}
                              placeholder="Enter Business Description"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="yearsInBusiness"
                            >
                              Years in Business
                            </label>
                            <input
                              type="text"
                              id="yearsInBusiness"
                              className="form-control"
                              name="yearsInBusiness"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.yearsInBusiness}
                              placeholder="Enter Years in Business"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="references">
                              References
                            </label>
                            <input
                              type="text"
                              id="references"
                              className="form-control"
                              name="references"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.references}
                              placeholder="Enter References"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "complianceCertifications" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        Edit Compliance and Certifications{" "}
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              id="regulatoryCompliance"
                              className="form-check-input"
                              name="regulatoryCompliance"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              checked={formData.regulatoryCompliance}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="regulatoryCompliance"
                            >
                              Regulatory Compliance
                            </label>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              id="licensesAndPermits"
                              className="form-check-input"
                              name="licensesAndPermits"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              checked={formData.licensesAndPermits}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="licensesAndPermits"
                            >
                              Licenses and Permits
                            </label>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group form-check">
                            <input
                              type="checkbox"
                              id="insuranceCoverage"
                              className="form-check-input"
                              name="insuranceCoverage"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              checked={formData.insuranceCoverage}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="insuranceCoverage"
                            >
                              Insurance Coverage
                            </label>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="mailingAddressLine1"
                            >
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ScopeWors" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Scope of Work </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="projectRequirements"
                            >
                              Project Requirements
                            </label>
                            <input
                              type="text"
                              id="projectRequirements"
                              className="form-control"
                              name="projectRequirements"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.projectRequirements}
                              placeholder="Enter Project Requirements"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="specifications"
                            >
                              Specifications
                            </label>
                            <input
                              type="textarea"
                              id="specifications"
                              className="form-control"
                              name="specifications"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.specifications}
                              placeholder="Enter Specifications"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "BillingPaymentTerms" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Billing and Payment Terms</h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="paymentTerms"
                            >
                              Payment Terms
                            </label>
                            <input
                              type="text"
                              id="paymentTerms"
                              className="form-control"
                              name="paymentTerms"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.paymentTerms}
                              placeholder="Enter Payment Terms"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="billingRates"
                            >
                              Billing Rates
                            </label>
                            <input
                              type="textarea"
                              id="billingRates"
                              className="form-control"
                              name="billingRates"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.billingRates}
                              placeholder="Enter Billing Rates"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ContractualAgreements" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Contractual Agreements</h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="contractTerms"
                            >
                              Contract Terms
                            </label>
                            <input
                              type="text"
                              id="contractTerms"
                              className="form-control"
                              name="contractTerms"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.contractTerms}
                              placeholder="Enter Contract Terms"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="insuranceRequirements"
                            >
                              Insurance Requirements
                            </label>
                            <input
                              type="textarea"
                              id="insuranceRequirements"
                              className="form-control"
                              name="insuranceRequirements"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.insuranceRequirements}
                              placeholder="Enter Insurance Requirements"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload"
                              className="form-control"
                              name="docsUpload"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "QualityAssuranceInspection" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        Edit Quality Assurance and Inspection{" "}
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="qualityAssurancePlan"
                            >
                              Quality Assurance Plan
                            </label>
                            <input
                              type="textarea"
                              id="qualityAssurancePlan"
                              className="form-control"
                              name="qualityAssurancePlan"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.qualityAssurancePlan}
                              placeholder="Enter Quality Assurance Plan"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="inspectionAndAcceptanceCriteria"
                            >
                              Inspection and Acceptance Criteria
                            </label>
                            <input
                              type="textarea"
                              id="inspectionAndAcceptanceCriteria"
                              className="form-control"
                              name="inspectionAndAcceptanceCriteria"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={
                                formData.inspectionAndAcceptanceCriteria
                              }
                              placeholder="Enter Inspection and Acceptance Criteria"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload4">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload4"
                              className="form-control"
                              name="docsUpload4"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload4}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName ===
                "EnvironmentalSustainabilityConsiderations" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        Edit Environmental and Sustainability Considerations{" "}
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id="environmentalCompliance"
                              className="form-check-input"
                              name="environmentalCompliance"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              checked={formData.environmentalCompliance}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="environmentalCompliance"
                            >
                              Environmental Compliance
                            </label>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="wasteManagementProcedures"
                            >
                              Waste Management Procedures
                            </label>
                            <input
                              type="textarea"
                              id="wasteManagementProcedures"
                              className="form-control"
                              name="wasteManagementProcedures"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.wasteManagementProcedures}
                              placeholder="Enter Waste Management Procedures"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload2">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload2"
                              className="form-control"
                              name="docsUpload2"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload2}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "EmergencyResponseContingencyPlans" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">
                        Edit Emergency Response and Contingency Plans{" "}
                      </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="emergencyContactPerson"
                            >
                              Contact Person
                            </label>
                            <input
                              type="text"
                              id="emergencyContactPerson"
                              className="form-control"
                              name="emergencyContactPerson"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.emergencyContactPerson}
                              placeholder="Enter Contact Person"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="emergencyContactNumber"
                            >
                              Emergency Contact Number
                            </label>
                            <input
                              type="text"
                              id="emergencyContactNumber"
                              className="form-control"
                              name="emergencyContactNumber"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.emergencyContactNumber}
                              placeholder="Enter Emergency Contact Number"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="emergencyProcedures"
                            >
                              Emergency Procedures
                            </label>
                            <input
                              type="text"
                              id="emergencyProcedures"
                              className="form-control"
                              name="emergencyProcedures"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.emergencyProcedures}
                              placeholder="Enter Emergency Procedures"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload3">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload3"
                              className="form-control"
                              name="docsUpload3"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload3}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ConflictResolution" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Conflict Resolution</h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="disputeResolutionProcess"
                            >
                              Dispute Resolution Process
                            </label>
                            <input
                              type="text"
                              id="disputeResolutionProcess"
                              className="form-control"
                              name="disputeResolutionProcess"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.disputeResolutionProcess}
                              placeholder="Enter Dispute Resolution Process"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="docsUpload5">
                              Docs Upload
                            </label>
                            <input
                              type="file"
                              id="docsUpload5"
                              className="form-control"
                              name="docsUpload5"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.docsUpload5}
                              placeholder="Enter Docs Upload"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ViewContractorDetails;
