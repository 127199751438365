import makeApiCall from "./axiosIndex";

const ApprovalHeirarchyApis = {
  fetchApprovalHeirarchy: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchApprovalHierarchy" }),
  deleteApprovalHeirarchyById: (id: string): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteApprovalHierarchyById`,
      params: { id },
    }),
  createApprovalHeirarchy: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createApprovalHierarchy",
      data,
    }),
  fetchApprovalHeirarchyById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchApprovalHierarchyById`,
      params: { id },
    }),
  updateApprovalHierarchyById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/seller/updateApprovalHierarchyById?id=${id}`,
      data,
    }),
  fetchApprovalHierarchyByModuleId: (moduleId: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchApprovalHierarchyByModuleId?id=${moduleId}`,
    }),
};

export default ApprovalHeirarchyApis;
