import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";

interface PurchaseRequestAsideProps {
    updateSm: (value: boolean) => void;  // Function to update state
    sm: boolean;  // Boolean state
}

const ReverseAuctionAside: FC<PurchaseRequestAsideProps> = ({ updateSm, sm }) => {
    const [profileName, setProfileName] = useState<string>("Abu Bin Ishtiak");
    const location = useLocation();

    useEffect(() => {
        sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{fontSize: 14 }}>Reverse Auction</h6>
                   
                </div>
            </div>
           
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                        <Link to="/reverse-auction-new" className={isActive(`${process.env.PUBLIC_URL}/reverse-auction-new`)}>
                            <Icon name="plus-fill-c" />
                            <span>Add New</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/reverse-auction-approvals" className={isActive(`${process.env.PUBLIC_URL}/reverse-auction-approvals`)}>
                            <Icon name="check-fill-c" />
                            <span>Approvals</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/reverse-auction-supplier-responses" className={isActive(`${process.env.PUBLIC_URL}/reverse-auction-supplier-responses`)}>
                            <Icon name="chat" />
                            <span>Supplier Responses</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/reverse-auction-comparison" className={isActive(`${process.env.PUBLIC_URL}/reverse-auction-comparison`)}>
                            <Icon name="col-view" />
                            <span>Comparison</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/reverse-auction-conversion" className={isActive(`${process.env.PUBLIC_URL}/reverse-auction-conversion`)}>
                            <Icon name="swap" />
                            <span>Conversion</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/reverse-auction-view" className={isActive(`${process.env.PUBLIC_URL}/reverse-auction-view`)}>
                            <Icon name="eye-fill" />
                            <span>View</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/track-rfqs" className={isActive(`${process.env.PUBLIC_URL}/track-rfqs`)}>
                            <Icon name="activity-round-fill" />
                            <span>History</span>
                        </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                        <Link to="/reverse-auction-analytics" className={isActive(`${process.env.PUBLIC_URL}/reverse-auction-analytics`)}>
                            <Icon name="growth-fill" />

                            <span>Analytics</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ReverseAuctionAside ;
