import React, { FC } from 'react';
import classNames from 'classnames';

interface AppRootProps {
  className?: string;
  children?: React.ReactNode;
}

const AppRoot: FC<AppRootProps> = ({ className, children, ...props }) => {
  const compClass = classNames({
    'nk-app-root': true,
    [`${className}`]: className,
  });

  return (
    <div className={compClass} {...props}>
      {children}
    </div>
  );
};

export default AppRoot;
