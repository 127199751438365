import makeApiCall from "./axiosIndex";


const orgsApis = {
    fetchOrg: (): Promise<any> => makeApiCall<any>({
        method: 'get',
        url: '/seller/fetchOrg'
    }),
    updateOrgById: (id: any, data: any): Promise<any> =>
        makeApiCall<any>({
            method: "put",
            url: `/seller/updateOrg`,
            params: { id },
            data,
        }),
    getCountrys: (): Promise<any> =>
        makeApiCall<any>({
            method: "get",
            url: `/misc/countries`,
        }),

    getStatesByCountry: (id: any): Promise<any> =>
        makeApiCall<any>({
            method: "get",
            url: `/misc/stateByCountry`,
            params: { countryId: id },
        }),

    getDistrictsByState: (stateId: number): Promise<any> => makeApiCall<any>({
        method: 'get',
        url: `/misc/districtByState`,
        params: { stateId }
    }),

};

export default orgsApis;