import makeApiCall from "../master/axiosIndex";

const purchaseOrderApis = {
  fetchPurchaseOrders: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllSupplierOrders",
    }),
  fetchOrder: (id: string): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/order-vs-billing/fetchOrder?orderNumber=${id}`,
    }),

  fetchAllUserApprovalOrders: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllUserApprovalOrders",
    }),
  purchaseOrderUpdate: (orderNumber: string, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/order-vs-billing/amendmentOrder/${orderNumber}`,
      data,
    }),
  fetchAllSupplierPendingOrders: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllSupplierPendingOrders",
    }),
  acceptOrder: (orderNumber: string): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/order-vs-billing/acceptOrder?orderNumber=${orderNumber}`,
    }),
  rejectOrder: (orderNumber: string, rejectedReason: string): Promise<any> =>
    makeApiCall<any>({
      method: "patch",
      url: `/order-vs-billing/rejectOrder?orderNumber=${orderNumber}&rejectedReason=${rejectedReason}`,
    }),
  fetchAllSupplierOrdersForInvoice: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/order-vs-billing/fetchAllSupplierOrdersForInvoice",
    }),
};

export default purchaseOrderApis;
