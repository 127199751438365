import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import { Icon, Button, RSelect } from "../../../components/Component";
import { Row, Col } from "reactstrap";
import FormCheckbox from "../../form-components/CheckboxComponent";
const ComplianceandCertifications: React.FC = (props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const submitForm = (data: any) => {
    props.next();
  };
  // const [formData, setFormData] = useState({
  //     companyName: "",
  //     contactPerson: "",
  //     email: "",
  //     phoneNumber: "",
  //     mailingAddressLine1: "",
  // });

  // const onInputChange = (e: any) => {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  return (
    <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
      <Row className="gy-4">
        <Col md="6">
          <FormInput
            name="regulatoryCompliancedocsUpload"
            label="Regulatory Compliance Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <Col md="6">
          <FormInput
            name="licensesAndPermitsdocsUpload"
            label="Licenses And Permits Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <Col md="6">
          <FormInput
            name="insuranceCoveragedocsUpload"
            label="Insurance Coverage Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <Col md="6">
          <FormInput
            name="ScopeofWorkdocsUpload"
            label="Scope of Work Docs Upload"
            type="file"
            placeholder="Docs Upload"
            // disabled={action === "view"}
          />
        </Col>
        <Col md="6">
          <FormInput
            name="BillingandPaymentTermsdocsUpload"
            label="Billing and Payment Terms Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <Col md="6">
          <FormInput
            name="contractTermsdocsUpload"
            label="Contract Terms Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <Col md="6">
          <FormInput
            name="environmentalCompliancedocsUpload"
            label="Environmental Compliance Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <Col md="6">
          <FormInput
            name="wasteManagementProceduresdocsUpload"
            label="Waste Management Procedures Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <Col md="6">
          <FormInput
            name="qualityAssurancePlandocsUpload"
            label="Quality Assurance Plan Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
        <Col md="6">
          <FormInput
            name="disputeResolutionProcessdocsUpload"
            label="Dispute Resolution Process Docs Upload"
            type="file"
            placeholder="Docs Upload"
          />
        </Col>
      </Row>
      <div className="actions clearfix">
        <ul>
          <li>
            <Button color="primary" type="submit">
              Next
            </Button>
          </li>
          <li>
            <Button color="primary" onClick={props.prev}>
              Previous
            </Button>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default React.memo(ComplianceandCertifications);
