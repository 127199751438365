import React, { useContext, useMemo } from "react";
import { HolderOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import type { TableColumnsType } from "antd";

interface DataType {
  key: string;
  MaterialCategory: string;
  materialName: string;
  hsnCode: string;
  quantity: string;
  uomId: string;
  unitPrice: string;
  amount: string;
  cgstPercentage: string;
  cgstAmount: string;
  sgstPercentage: string;
  sgstAmount: string;
  igstPercentage: string;
  igstAmount: string;
  needByDate?: string;
  attachment: string;
  shippingAddress: string;
  state?: string;
  address?: string;
  district?: string;
  city?: string;
  pincode?: string;
  contactPerson?: string;
  contactMobile?: string;
  contactMail?: string;
  cess?: string;
  description: string;
  lineTotal: string;
}
const PurchaseOrderAnalytics: React.FC = () => {
  return (
    <div
      style={{
        marginTop: "100px",
      }}
    >
      Analytics
    </div>
  );
};

export default PurchaseOrderAnalytics;
