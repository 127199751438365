import React, { FC } from 'react';
import Icon from '../../components/icon/Icon';

interface ToggleProps {
  className?: string;
  click: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  icon: string;
}

const Toggle: FC<ToggleProps> = ({ className, click, icon }) => {
  return (
    <a
      href="#toggle"
      className={className ? className : ''}
      onClick={(ev) => {
        ev.preventDefault();
        click(ev);
      }}
    >
      <Icon name={icon} />
    </a>
  );
};

export default Toggle;
