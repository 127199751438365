import React, { useState, useEffect, FC } from "react";
import Content from "../../../layout/content/Content";
import {
  Badge,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  BlockDes,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  PaginationComponent,
} from "../../../components/Component";
import Swal from "sweetalert2";
import FormatDate from "../DateFormater";
import Export from "../../../components/export/Export";
import DeliveryAddressMaster from "./DeliveryAddressMaster";
const DeliveryAddress: FC = () => {
  const [sm, setSm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [detail, setDetail]: any = useState({});
  const [popUpContent, setPopUpContent] = useState<JSX.Element | null>(null);
  const handleModelPopUp = (id?: any, type?: string) => {
    const content = (
        <DeliveryAddressMaster
            formAction={type}
            id={id}
            toggleModelPopUp={() => {
            setIsOpen(false);
            fetchData();
            }}
        />
        );
    setPopUpContent(content);
    setIsOpen(true);
  };
  const fetchData = async () => {
    // const { data, status, message } = await costCenterApis.fetchCostCenter();
    // if (status) {
    //   setRowData(data);
    //   setOriginalData(data); // Store the original data
    // } else {
    //   Swal.fire("Error", message, "error");
    // }
  };
  const handleDelete = (id: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCostCenterById(id);
      }
    });
  };
  const deleteCostCenterById = async (id: any) => {
    // const { status, message } = await costCenterApis.deleteCostCenterById(id);
    // if (status) {
    //   fetchData();
    //   Swal.fire("Deleted!", message, "success");
    // } else {
    //   Swal.fire("Error", message, "error");
    // }
  };
  const [rowData, setRowData] = useState<any>([]);
  const [originalData, setOriginalData] = useState([]);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [tablesm, updateTableSm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  const viewChange = () => {
    if (window.innerWidth < 990) {
    } else {
      setSm(false);
    }
  };
  const loadDetail = (id: any) => {
    let index = rowData.findIndex((item: any) => item.id === id);
    setDetail(rowData[index]);
  };

  useEffect(() => {
    fetchData();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = originalData.filter((item: any) => {
        return item.costCenterName
          .toLowerCase()
          .includes(onSearchText.toLowerCase());
      });
      setRowData([...filteredObject]);
    } else {
      setRowData([...originalData]); // Reset to original data
    }
  }, [onSearchText, originalData]);

  const sortFunc = (params: any) => {
    // let defaultData = rowData;
    // if (params === "asc") {
    //   let sortedData = defaultData.sort((a: any, b: any) =>
    //     a.costCenterName.localeCompare(b.costCenterName)
    //   );
    //   setRowData([...sortedData]);
    // } else if (params === "dsc") {
    //   let sortedData = defaultData.sort((a: any, b: any) =>
    //     b.costCenterName.localeCompare(a.costCenterName)
    //   );
    //   setRowData([...sortedData]);
    // }
  };

  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = rowData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const onFilterChange = (e: any) => {
    setSearchText(e.target.value);
  };

  return (
    <React.Fragment>
      <Head title="Delivery Address"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Delivery Address
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {rowData.length} Address.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => setSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Export data={rowData} />
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        color="primary"
                        onClick={() => {
                          handleModelPopUp(null, "");
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add </span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable
            className="card-stretch"
            bodyClassName={undefined}
            title={undefined}
          >
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  {/* <h5 className="title">Cost Centers</h5> */}
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${
                            tablesm ? "active" : ""
                          }`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${
                            tablesm ? "content-active" : ""
                          }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => updateTableSm(false)}
                              >
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                >
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li
                                      className={
                                        itemPerPage === 10 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemPerPage === 15 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li
                                      className={sort === "dsc" ? "active" : ""}
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={sort === "asc" ? "active" : ""}
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`card-search search-wrap ${!onSearch && "active"}`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by name"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <DataTableBody compact className={undefined} bodyclass={undefined}>
              <DataTableHead>
                <DataTableRow className={undefined} size={undefined}>
                  <span className="sub-text">#</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Construction Site</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Delivery Location </span>
                </DataTableRow>
                <DataTableRow size="sm" className={undefined}>
                  <span className="sub-text">State</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Created Date</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}></DataTableRow>
              </DataTableHead>
              {currentItems.length > 0
                ? currentItems.map((item: any, index: number) => {
                    return (
                      <DataTableItem
                        key={item.id}
                        className={undefined}
                        style={{ color: "black" }}
                      >
                        <DataTableRow size="md" className={undefined}>
                          <span>{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.constructionSite}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.deliveryLocationName}</span>
                        </DataTableRow>
                        <DataTableRow size="sm" className={undefined}>
                          <span>{item.state}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{FormatDate(item.createdDate)}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span
                            className={`tb-status text-${
                              item.status === "Active" ? "success" : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <ul className="nk-tb-actions">
                            <li
                              className="nk-tb-action-hidden"
                              onClick={() => handleModelPopUp(item.id, "edit")}
                            >
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                                iconClass={undefined}
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => {}}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          handleModelPopUp(item.id, "edit");
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          loadDetail(item.id);
                                          setViewModal(true);
                                        }}
                                      >
                                        <Icon name="focus"></Icon>
                                        <span>View Details</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          handleDelete(item.id);
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={rowData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
      </Content>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setIsOpen(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>{popUpContent}</ModalBody>
      </Modal>
      <Modal
        isOpen={viewModal}
        toggle={() => setViewModal(false)}
        className="modal-dialog-centered"
        size="lg"
        backdrop="static"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setViewModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="nk-modal-head">
            <h4 className="nk-modal-title title">
             Delivery Address Details -{" "}
              <small className="text-primary"> {detail?.constructionSite}</small>
            </h4>
          </div>
          <div className="nk-tnx-details mt-sm-3">
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Construction Site</span>
                <span className="caption-text">{detail?.siteId}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Delivery Location Name</span>
                <span className="caption-text">{detail?.deliveryLocationName}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">State</span>
                <span className="caption-text">{detail?.state}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">District</span>
                <span className="caption-text">{detail?.district}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">City</span>
                <span className="caption-text">{detail?.city}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Address</span>
                <span className="caption-text">{detail?.address}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Pincode</span>
                <span className="caption-text">{detail?.pincode}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Contact Person</span>
                <span className="caption-text">{detail?.contactPerson}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Contact Mobile</span>
                <span className="caption-text">{detail?.contactMobile}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Contact Mail</span>
                <span className="caption-text">{detail?.contactMail}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Status</span>
                <Badge
                  color={detail?.status === "Active" ? "success" : "danger"}
                  size="md"
                >
                  {detail?.status}
                </Badge>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Date</span>
                <span className="caption-text">
                  {" "}
                  {FormatDate(detail?.createdDate)}
                </span>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DeliveryAddress;
