import React, { useState, useEffect } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Label, Input, Row, Col, Alert } from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import supplierApis from "../../../api/master/supplier";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import FormCheckbox from "../../form-components/CheckboxComponent";
import FormDatePicker from "../../form-components/DatePicker";
import usersApis from "../../../api/master/users";
import rolesApi from "../../../api/master/roles";

interface SelectOptions {
  label: string;
  value: string;
}
const Accessibility: React.FC = (props: any) => {
  const navigate = useNavigate();

  const methods = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [accessibilityKeys, setaccessibilityKeys]:any = useState([]);
  const selectAll = watch('selectAll', false);

  const updatedAccessibilityOptions = [
    {
      label: "Sub Feature",
      value: "Sub Feature",
    },
    {
      label: "Create / Add",
      value: "ADD",
    },
    {
      label: "View",
      value: "VIEW",
    },
    {
      label: "Edit / Update",
      value: "EDIT",
    },
    {
      label: " Delete / Inactive",
      value: "CANCEL",
    },
    {
      label: "Approve / Reject",
      value: "APPROVE",
    },
  ];

  const readOnlyAddModules = ["Dashboard"];
  const readOnlyUpdateModules = ["Dashboard"];
  const readOnlyRejectModules = ["Dashboard", "GRN"];
  const readOnlyDeleteModules = [
    "Dashboard",
    "Purchase Request",
    "Quotation",
    "Purchase Order",
    "Invoice",
    "GRN",
    "Payments",
    "Credit Note",
  ];

  const submitForm = (data: any) => {
    const accessibility: {
      module: string;
      add: boolean;
      view: boolean;
      edit: boolean;
      cancel: boolean;
      approve: boolean;
      // reject: boolean;
    }[] = [];
    accessibilityKeys.forEach((key: SelectOptions) => {
      accessibility.push({
        module: key.value,
        add: data[`${key.value}_add`],
        view: data[`${key.value}_view`],
        edit: data[`${key.value}_edit`],
        cancel: data[`${key.value}_cancel`],
        approve: data[`${key.value}_approve`],
        // reject: data[`${key.value}_approve`],
      });
      delete data[`${key.value}_add`];
      delete data[`${key.value}_view`];
      delete data[`${key.value}_edit`];
      delete data[`${key.value}_cancel`];
      delete data[`${key.value}_approve`];
      // delete data[`${key.value}_reject`];
    });
    data.accessibility = accessibility;
    console.log(JSON.stringify(data));
    createUser(data);
  };
  const createUser = async (data: any) => {
    const response = await rolesApi.createRole(data);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "Role has been created", "success");
      navigate(`/roles`);
    } else {
      console.log("Error in form submission", message, response);
      let errorMessage = "An error occurred";
      let fullErrorMessage;
      if (typeof message == "string") {
        fullErrorMessage = `${errorMessage}\n${"-"}\n${message}`;
      } else {
        fullErrorMessage = `${errorMessage}\n${"-"}\n${response?.message?.join(
          ", "
        )}`;
      }
      // const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };

  const fetchModuleConfig = async () => {
    try {
      const res = await usersApis.getModuleConfig();
      const data = res.data;
      setaccessibilityKeys(
        data.map((obj: any) => ({
          label: obj.moduleName,
          value: obj.moduleID.toString(),
        }))
      );
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchModuleConfig();
  }, []);

  useEffect(() => {
    setaccessibilityKeys((prevKeys:any) =>
      prevKeys.map((item:any) => ({
        ...item,
        checked: selectAll,
      }))
    );
  }, [selectAll]);

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const newSelectAll = isChecked;
    setValue('selectAll', isChecked);
    accessibilityKeys.forEach((key:any)=> {
          updatedAccessibilityOptions.forEach((option:any) => {
            if (
              !(
                (option.value === "ADD" && readOnlyAddModules.includes(key.label)) ||
                (option.value === "EDIT" && readOnlyUpdateModules.includes(key.label)) ||
                (option.value === "CANCEL" && readOnlyDeleteModules.includes(key.label)) ||
                (option.value === "APPROVE" && readOnlyRejectModules.includes(key.label))
              )
            )
             {
              setValue(`${key.value}_${option.value.toLowerCase()}`, newSelectAll);
            }
          });
        });
      }

  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
        <div className="d-flex justify-content-end">
            <FormCheckbox
              name="select_all"
              optionLabel="Select All"
              noGap={true}
              size={"small"}
              onChange={handleSelectAllChange}
            />
          </div>
          <table className="table table-hover" id="accessesbility">
            <thead>
              <tr>
                {updatedAccessibilityOptions.map((item: SelectOptions) => {
                  return <th key={item.value}>{item.label}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {accessibilityKeys.map((item: any) => {
                return (
                  <tr>
                    <td>{item.label}</td>
                    <td>
                      <FormCheckbox
                        disabled={readOnlyAddModules.includes(item.label)}
                        name={`${item.value}_add`}
                        optionLabel=""
                        noGap={true}
                        size={"small"}
                      />
                    </td>
                    <td>
                      <FormCheckbox
                        name={`${item.value}_view`}
                        optionLabel=""
                        noGap={true}
                        size={"small"}
                      />
                    </td>
                    <td>
                      <FormCheckbox
                        disabled={readOnlyUpdateModules.includes(item.label)}
                        name={`${item.value}_edit`}
                        optionLabel=""
                        noGap={true}
                        size={"small"}
                      />
                    </td>
                    <td>
                      <FormCheckbox
                        disabled={readOnlyDeleteModules.includes(item.label)}
                        name={`${item.value}_cancel`}
                        optionLabel=""
                        noGap={true}
                        size={"small"}
                      />
                    </td>
                    <td>
                      <FormCheckbox
                        disabled={readOnlyRejectModules.includes(item.label)}
                        name={`${item.value}_approve`}
                        optionLabel=""
                        noGap={true}
                        size={"small"}
                      />
                    </td>
                    {/* <td>
                      <FormCheckbox
                        name={`${item.value}_reject`}
                        optionLabel=""
                        noGap={true}
                        size={"small"}
                      />
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Row>

        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Submit Form
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default Accessibility;
