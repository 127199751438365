import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface HeadProps {
  title?: string;
}

const Head: FC<HeadProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{title ? `${title} | ` : ''}ProcureX | Simplifying Complexities</title>
    </Helmet>
  );
};

export default Head;
