import React, { FC, ReactNode } from "react";
import SimpleBar from "simplebar-react";

interface SidebarProps {
  toggleState: boolean;  // Define the type of 'toggleState' explicitly
  children?: ReactNode;
}
const Sidebar: FC<SidebarProps> = ({ toggleState, children }) => {
  return (
    <>
      <div
        className={`card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl ${
          toggleState ? "content-active" : ""
        }`}
        data-content="userAside"
        data-toggle-screen="xxl"
        data-toggle-overlay="true"
        data-toggle-body="true"
        id="sidePanel_01"
      >
        <SimpleBar className="card-inner-group">{children}</SimpleBar>
      </div>
    </>
  );
};

export default Sidebar;
