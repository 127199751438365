import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../../layout/content/Content";
import { Card, Badge, Modal, ModalBody } from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
} from "../../../components/Component";
import OrgChart from "./OrgChart";
import Swal from "sweetalert2";
import RoleHeirarchyMaster from "./RoleHeirarchyMaster";
import rolesApi from "../../../api/master/roles";
import Export from "../../../components/export/Export";

const ReportingHeirarchy: FC = () => {
  const [sm, setSm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [data, setData] = useState([]);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [popUpContent, setPopUpContent] = useState<JSX.Element | null>(null);
  const handleModelPopUp = (id?: any, type?: string) => {
    const content = (
      <RoleHeirarchyMaster
        formAction={type}
        id={id}
        toggleModelPopUp={() => {
          fetchData();
          setIsOpen(false);
          setSelectedNode(null);
        }}
      />
    );
    setPopUpContent(content);
    setIsOpen(true);
  };
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  const fetchData = async () => {
    try {
      const { data, status, message } =
        await rolesApi.fetchReportingHierarchy();
      if (status) {
        setData(data);
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong", "error");
    }
  };

  useEffect(() => {
    viewChange();
    fetchData();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  useEffect(() => {
    if (selectedNode) {
      handleModelPopUp(selectedNode?.id, "edit");
    }
  }, [selectedNode]);
  return (
    <>
      <Head title="Role Heirarchy" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Role Hierarchy
              </BlockTitle>
              <BlockDes className="text-soft">
                <p></p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => setSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Export data={data} />
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        color="primary"
                        onClick={() => {
                          handleModelPopUp(null, "");
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add </span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockDes>
                <OrgChart nodesData={data} setSelectedNode={setSelectedNode} />
              </BlockDes>
              <Modal
                isOpen={isOpen}
                toggle={() => setIsOpen(false)}
                className="modal-dialog-centered"
                size="lg"
                backdrop="static"
              >
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setIsOpen(false);
                    fetchData();
                    setIsOpen(false);
                    setSelectedNode(null);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>

                <ModalBody>{popUpContent}</ModalBody>
              </Modal>
              <Block>{/* Further component implementations */}</Block>
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default ReportingHeirarchy;
