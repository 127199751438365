import axiosInstance from '../axiosInstance';

import { LoginCredentials, AuthResponse } from '../types/auth/authTypes';

export const login = async (credentials: LoginCredentials): Promise<any> => {
  try {
    const response = await axiosInstance.post<AuthResponse>('/auth/login', credentials);
    return response.data;
  } catch (error:any) {
    throw new Error('Login failed: ' + error.message);
  }
};

export const setPassword = async (credentials:any,accessToken:any): Promise<any> => {
  try {
    const headers = accessToken ? { 'Authorization': `${accessToken}` } : {};
    const response = await axiosInstance.put<AuthResponse>('/users/editPassword', credentials,{ headers });
    return response.data;
  } catch (error:any) {
    throw new Error('Login failed: ' + error.message);
  }
};

export const reSetPassword = async (data:any): Promise<any> => {
  try {
    const response = await axiosInstance.post<AuthResponse>('/users/sendResetEmailToRegisteredUser', data);
    return response.data;
  } catch (error:any) {
    throw new Error('Reset failed: ' + error.message);
  }
};

export const logout = async () => {
  try {
    const response = await axiosInstance.post<AuthResponse>('/auth/logout',);
    return response.data;
  } catch (error:any) {
    throw new Error('Logout failed: ' + error.message);
  }
};
 

