import makeApiCall from "./axiosIndex";

const usersApis = {

  fetchBuyerUser: (): Promise<any> =>
    makeApiCall<any>({ method: "get", url: "/seller/fetchBuyerUser" }),

  deleteBuyerById: (buyerId: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/seller/deleteBuyerUserById?id=${buyerId}`
    }),

  createBuyer: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: "/seller/createBuyerUser",
      data,
    }),

  updateBuyerById: (id: any, data: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/seller/updateBuyerUserById`,
      params: { id: id },
      data,
    }),

  fetchBuyerById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchBuyerUserById`,
      params: { id },
    }),


  fetchRolesData: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchRole`,
    }),

  getDeptData: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchDept`,
    }),

  fetchContractor: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchContractor`,
    }),

  fetchConsultant: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/seller/fetchConsultant`,
    }),

  getCountrys: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/countries`,
    }),
    
    getStatesByCountry: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/misc/stateByCountry`,
      params: { countryId: id },
    }),

    getDistrictsByState: (stateId: number): Promise<any> => makeApiCall<any>({
      method: 'get',
      url: `/misc/districtByState`,
      params: { stateId }
    }),

    getModuleConfig: (): Promise<any> =>
      makeApiCall<any>({
        method: "get",
        url: `/seller/fetchModuleConfig`,
      }),
    getCurrentUser: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/users/currentUser`,
    }),

    
    fetchUsersByRoleId: (id: any): Promise<any> =>
      makeApiCall<any>({
        method: "get",
        url: `/buyer/fetchUsersByRoleId`,
        params: { id },
      }),

};

export default usersApis;